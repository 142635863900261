import React from 'react'

import { Cards } from '../../components/cards'

function ClienteCadastroResponsavel({ register, setValue }) {
  return (
    <Cards>
      <section>
        <div className='row'>
          <div className='col'>
            <h5>Responsável pelo cadastro</h5>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6 col-sm-12 mb-3'>
            <label htmlFor='usuario_cadastro'>Usuário</label>
            <input
              className='form-control'
              type='text'
              readOnly
              {...register('usuario_cadastro.nome')}
            />
          </div>
          <div className='col-md-3 col-sm-12 mb-3'>
            <label htmlFor='data_cadastro'>Data do cadastro</label>
            <input
              className='form-control'
              type='date'
              readOnly
              {...register('data_cadastro')}
            />
          </div>
          <div className='col-md-3 col-sm-12 mb-3'>
            <label htmlFor='hora_cadastro'>Hora do cadastro</label>
            <input
              className='form-control'
              type='time'
              readOnly
              {...register('hora_cadastro')}
            />
          </div>
         
        </div>
      </section>
    </Cards>
  )
}

export { ClienteCadastroResponsavel }
