import React from 'react'
import { FiTrash2 } from 'react-icons/fi'

function TagsStatic({ chave, nome, remover }) {
  return (
    <>
      <button className="btn btn-sm btn-outline-secondary rounded-0 mb-3" type='button'>
        <span>
          {nome}
        </span>{' '}
      </button>
      <span className='btn btn-sm btn-danger rounded-0 mb-3' role='button' onClick={() => remover(chave)}><FiTrash2 /></span>&nbsp;&nbsp;&nbsp;
    </>
  )
}

export { TagsStatic }