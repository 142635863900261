import React from 'react'
import { Link } from 'react-router-dom'

import './notfound.css'

function NotFound() {
  return (
    <>
      <main id='main'>
        <div className='error'>
          <h1>404. Oops, a página não foi encontrada.</h1>
          <br />
          <br />
          <Link className='btn btn-dark' to='/cliente'> Voltar à página inicial </Link>
        </div>
      </main>

    </>
  )
}

export { NotFound }
