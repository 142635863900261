import React, { useState } from 'react'
import { FiCheck, FiEye } from 'react-icons/fi';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Spinner } from 'react-bootstrap';

import UsuarioService from '../../services/usuario'
import { useAuth } from '../../services/auth';
import { Cards } from '../../components/cards'
import { ModalAviso } from '../../components/modalAviso';
import { CampoObrigatorio } from '../../components/campoObrigatorio';

function PerfilSenha({ getValues }) {
  const { SignOutForce } = useAuth()
  const validationSchema = yup.object().shape({
    senhaatual: yup.string().required('A senha atual é obrigatória'),
    novasenha: yup.string()
      .min(6, 'A senha deve conter no mínimo 6 caracteres')
      .required('Nova senha obrigatória!'),
    repetirSenha: yup.string()
      .oneOf([yup.ref('novasenha'), null], 'Senhas diferentes!')
      .required('Confirme sua senha!'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) }
  const { register, formState: { errors }, handleSubmit, reset } = useForm(formOptions);
  const objVerSenha = <FiEye />
  const [isPassword, setIsPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalAviso, setModalAviso] = useState(false)
  const [showAlertErroServidor, setShowAlertErroServidor] = useState(false)
  const [alertError, setShowAlertError] = useState(false)
  const [messageError, setMessageError] = useState('')

  const togglePassword = (password) => {
    if (password !== 'repetirSenha') {
      setIsPassword(isPassword ? false : true)
    } else if (password !== 'senhaAtual') {
      setIsPassword(isPassword ? true : false)
    }
  }

  const onSubmit = (data, e) => {
    e.preventDefault()

    async function Editar() {
      UsuarioService
        .updatePassword({
          email: getValues('email'),
          senha_atual: data.senhaatual,
          nova_senha: data.novasenha
        })
        .then((dados) => {
          setModalAviso(true)
          setLoading(false)
          reset()
          setTimeout(() => {
            setModalAviso(false)
          }, 2000);
        })
        .catch((error) => {
          if (error.code) {
            switch (error.code) {
              case 0:
                setShowAlertErroServidor(true)
                setLoading(false)
                setTimeout(() => {
                  setShowAlertErroServidor(false)
                }, 5000)
                break
              case 403:
                SignOutForce()
                window.location.reload()
                break
              case 409:
                setMessageError(error.message)
                setShowAlertError(true)
                setLoading(false)
                setTimeout(() => {
                  setShowAlertError(false)
                  setLoading(false)
                }, 3000)
                break
              default:
                setMessageError(error.message)
                setShowAlertError(true)
                setTimeout(() => {
                  setShowAlertError(false)
                  setLoading(false)
                }, 3000)
                break
            }
          } else {
            setMessageError(error.message)
            setShowAlertError(true)
            setLoading(false)
          }
        })
    }


    Editar()
  }

  return (
    <>
      <ModalAviso show={modalAviso} message='Senha alterada com sucesso!' color='bg-success' backdrop={false} />
      <ModalAviso show={showAlertErroServidor} message='Verifique sua conexão com a internet.' color='bg-danger' backdrop={false} />
      <ModalAviso show={alertError} message={messageError} color='bg-warning' backdrop={false} />
      <form id='formPerfilSenha' onSubmit={handleSubmit(onSubmit)} >
        <Cards>
          <div className='row'>
            <div className='col-sm-12 col-md-6'>
              <label htmlFor='senhaatual'>Senha atual <CampoObrigatorio /></label>
              <div className='input-group mb-3'>
                <input className='form-control' type={isPassword ? 'text' : 'password'} {...register('senhaatual', { required: true })} />
                <i className='input-group-text' id='senhaatual' onClick={(e) => { togglePassword(e.target.id) }}>{objVerSenha}</i>
              </div>
              <span className='text-danger text-small d-block mb-2 mt-2'>{errors.senhaatual?.message}</span>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-6'>
              <label htmlFor='novasenha'>Nova senha</label>
              <div className='input-group mb-3'>
                <input className='form-control' type={isPassword ? 'text' : 'password'} {...register('novasenha', { required: true })} />
                <i className='input-group-text' id='novasenha' onClick={(e) => { togglePassword(e.target.id) }}>{objVerSenha}</i>
              </div>
              <span className='text-danger text-small d-block mb-2 mt-2'>{errors.novasenha?.message}</span>
            </div>
            <div className='col-sm-12 col-md-6'>
              <label htmlFor='repetirSenha'>Repetir nova senha</label>
              <div className='input-group mb-3'>
                <input className='form-control' type={isPassword ? 'text' : 'password'} {...register('repetirSenha', { required: true })} />
                <i className='input-group-text' id='repetirSenha' onClick={(e) => (togglePassword(e.target.id))}>{objVerSenha}</i>
              </div>
              <span className='text-danger text-small d-block mb-2 mt-2'>{errors.repetirSenha?.message}</span>
            </div>
          </div>
          <div className='row'>
            <div className='col' ></div>
            <div className='col-auto'>
              <button className='btn btn-success' type='submit' disabled={loading}>
                {
                  loading ? <><Spinner animation='border' size='sm' /> Aguarde...</> : <><FiCheck /> Alterar senha</>
                }
              </button>
            </div>
          </div>
        </Cards>
      </form>
    </>
  )
}

export { PerfilSenha }
