import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from '../routes'
import { Menu } from './Menu/navbar'

function Main() {
  return (
    <>
      <BrowserRouter>
        <Menu/>
        <div>
          <Routes />
        </div>
      </BrowserRouter>
    </>
  )
}

export default Main
