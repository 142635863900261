import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { useAuth } from '../services/auth'

function PrivateLogin({ component: Component, ...rest }) {
  const { isConnected } = useAuth();

  return (
    <Route
      {...rest}
      render={props =>
        !isConnected ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/cliente", state: { from: props.location } }} />
        )
      }
    />
  )
}

export default PrivateLogin
