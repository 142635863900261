import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form'
import { FiCheck, FiPlus } from 'react-icons/fi';

import FormValidate from '../../libs/form.validate';
import { setValueForm } from '../../libs/setValueForm';
import { CampoObrigatorio } from '../../components/campoObrigatorio';

const AdicionarFamiliar = ({ addFamiliar, preencheCampo, setPreencheCampo }) => {
  const { register, setValue, getValues, setFocus } = useForm()

  useEffect(() => {
    setTimeout(() => {
      setFocus('nome')

      let parseMoeda = preencheCampo?.emprego_renda?.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })

      if (!parseMoeda) parseMoeda = ''

      setValueForm(setValue,
        {
          data_nascimento: preencheCampo?.data_nascimento || '',
          emprego_descricao: preencheCampo?.emprego_descricao || '',
          emprego_possui: preencheCampo?.emprego_possui || false,
          emprego_renda: parseMoeda || '0,00',
          nome: preencheCampo?.nome || '',
          parentesco: preencheCampo?.parentesco || '',
          escolaridade: preencheCampo?.escolaridade || ''
        })

    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preencheCampo])

  const handleClickSalvar = () => {
    const result = getValues();

    if (!getValues('nome') || !getValues('data_nascimento')) {
      if (!getValues('nome')) {
        setFocus('nome')
        return;
      }

      setFocus('data_nascimento')
      return;
    }

    if (!result.hasOwnProperty("emprego_possui")) result.emprego_possui = false
    if (result.emprego_possui === undefined) result.emprego_possui = false

    handleClickNovo()
    addFamiliar(result)
  }

  const handleClickNovo = () => {
    setPreencheCampo({})
    setValueForm(setValue, {
      data_nascimento: '',
      emprego_descricao: '',
      emprego_possui: false,
      emprego_renda: '0,00',
      nome: '',
      parentesco: '',
      escolaridade: ''
    })
    setFocus('nome')
  }

  return (
    <>
      <div className='row'>
        <div className='col mb-3'>
          <label>Nome completo <CampoObrigatorio /> </label>
          <input
            className='form-control'
            type='text'
            {...register('nome')}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 col-sm-12 mb-3'>
          <label>Data de nascimento <CampoObrigatorio /> </label>
          <input
            className='form-control'
            type='date'
            {...register('data_nascimento')}
          />
        </div>
        <div className='col-md-6 col-sm-12 mb-3'>
          <label>Parentesco</label>
          <input
            className='form-control'
            type='text'
            {...register('parentesco')}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <label htmlFor='escolaridade'>Escolaridade</label>
          <input
            className='form-control'
            type='text'
            {...register('escolaridade')}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='checkbox'
              id='emprego_possui'
              {...register('emprego_possui')}
            />
            <label className='form-check-label' htmlFor='emprego_possui'>Possui emprego</label>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-8 col-sm-12 mb-3'>
          <label>Descrição</label>
          <input
            className='form-control'
            type='text'
            {...register('emprego_descricao')}
          />
        </div>
        <div className='col-md-4 col-sm-12 mb-3'>
          <label>R$ Renda</label>
          <input
            className='form-control'
            type='text'
            name='emprego_renda'
            {...register('emprego_renda')}
            onKeyUp={(e) => { setValue(e.target.name, FormValidate.onChange.Moeda(e.target?.value)) }
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'></div>
        <div className='col-auto mb-3'>
          <button className='btn btn-outline-success' type='button' onClick={() => handleClickNovo()}><FiPlus /> Novo</button>{' '}
          <button className='btn btn-success' type='button' onClick={() => handleClickSalvar()}><FiCheck /> Salvar</button>
        </div>
      </div>
    </>
  );
}

function ModalComposicaoFamiliar(props) {
  const addFamiliarTag = object => {
    const familiarFiltro = props.familiarTag.filter((value) => {
      if (!props.showDataModal?.hash) {
        return value
      }

      return value.hash !== props.showDataModal.hash
    })

    let strFamiliarTag = JSON.stringify(object);
    let objFamiliarTagBase64 = btoa(strFamiliarTag)

    object.hash = objFamiliarTagBase64
    props.setFamiliarTag([...familiarFiltro, object])
    props.setShowDataModal({})
  }

  return (
    <Modal
      {...props}
      id='modalComposicaoFamiliar'
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header custom closeButton closeLabel=''>
        <Modal.Title>
          Composição familiar
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AdicionarFamiliar
          addFamiliar={addFamiliarTag}
          preencheCampo={props.showDataModal}
          setPreencheCampo={props.setShowDataModal}
        />
        {props.renderComposicaoFamiliar()}
      </Modal.Body>
    </Modal>
  )
}

export default ModalComposicaoFamiliar;