function setValueForm(setValue, data) {
  if (data) {
    const obj = Object.keys(data)
    const val = Object.values(data)

    obj.map((value, index) => setValue(value, val[index]))
  }
}

export { setValueForm }
