import React from 'react'

import { Cards } from '../../components/cards';
import FormValidate from '../../libs/form.validate';

function ClienteCadastroVeiculo({ register, setValue }) {
  return (
    <Cards>
      <div className='row'>
        <div className='col'>
          <h5>Veículo</h5>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 col-sm-12 mt-3 mb-3'>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='checkbox' id='veiculo_possui' value={true} {...register('veiculo_possui')} />
            <label className='form-check-label' htmlFor='veiculo_possui'>Possui veículo</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='checkbox' id='veiculo_financiado' value={true} {...register('veiculo_financiado')} />
            <label className='form-check-label' htmlFor='veiculo_financiado'>Financiado</label>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-4 col-sm-12 mb-3'>
          <label htmlFor='veiculo_ano'>Ano</label>
          <input className='form-control' type='number' {...register('veiculo_ano')} />
        </div>

        <div className='col-md-4 col-sm-12 mb-3'>
          <label htmlFor='veiculo_modelo'>Modelo</label>
          <input className='form-control' type='text' {...register('veiculo_modelo')} />
        </div>

        <div className='col-md-4 col-sm-12 mb-3'>
          <label htmlFor='veiculo_parcela'>R$ Parcela</label>
          <input
            className='form-control'
            type='text'
            {...register('veiculo_parcela')}
            onKeyUp={
              (e) => {
                setValue(e.target.name, FormValidate.onChange.Moeda(e.target?.value))
              }
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <label htmlFor='veiculo_observacao'>Observação</label>
          <textarea className='form-control' rows='5' {...register('veiculo_observacao')} />
        </div>
      </div>
    </Cards>
  )
}

export { ClienteCadastroVeiculo }
