import ConfigURL from '../config/api'
import security from './security'

const acompanhamentoBeneficio = {}

acompanhamentoBeneficio.register = body => {
  return security.validation(ConfigURL.axios.post(ConfigURL.api.acompanhamentoBeneficio.post.register, body))
}

acompanhamentoBeneficio.listAll = () => {
  return security.validation(ConfigURL.axios.get(ConfigURL.api.acompanhamentoBeneficio.get.listAll ))
}

acompanhamentoBeneficio.filterAll = body => {
  return security.validation(ConfigURL.axios.get(`${ConfigURL.api.acompanhamentoBeneficio.get.listAll}?data_inicial=${body.data_inicial}&data_final=${body.data_final}&tipo_data=${body.tipo_data}&pessoa_cpf=${body.pessoa_cpf}&grupo_acao=${body.grupo_acao}`))
}

acompanhamentoBeneficio.list = (id) => {
  return security.validation(ConfigURL.axios.get(`${ConfigURL.api.acompanhamentoBeneficio.get.list}/${id}`))
}

acompanhamentoBeneficio.update = (id, body) => {
  return security.validation(ConfigURL.axios.put(`${ConfigURL.api.acompanhamentoBeneficio.put.update}/${id}`, body))
}

acompanhamentoBeneficio.updatePatch = (id, body) => {
  return security.validation(ConfigURL.axios.patch(`${ConfigURL.api.acompanhamentoBeneficio.put.update}/${id}`, body))
}

export default acompanhamentoBeneficio