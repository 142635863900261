import React from 'react'

import { Cards } from '../../components/cards';
import FormValidate from '../../libs/form.validate';

function ClienteCadastroBeneficio({ register, setValue }) {

  return (
    <Cards>
      <div className='row'>
        <div className='col'>
          <h5>Benefícios</h5>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-3 col-sm-12 mb-3'>
          <label htmlFor='beneficio_possui'>Possui</label>
          <select
            className='form-select'
            {...register('beneficio_possui')}
          >
            <option value={false}>Não</option>
            <option value={true}>Sim</option>
          </select>
        </div>

        <div className='col-md-3 col-sm-12 mb-3'>
          <label htmlFor='beneficio_doacao'>R$ Doação</label>
          <input
            className='form-control'
            type='text'
            {...register('beneficio_doacao')}
            onKeyUp={
              (e) => {
                setValue(e.target.name, FormValidate.onChange.Moeda(e.target?.value))
              }
            }
          />
        </div>

        <div className='col-md-3 col-sm-12 mb-3'>
          <label htmlFor='beneficio_bolsa_familia'>R$ Bolsa família</label>
          <input
            className='form-control'
            type='text'
            {...register('beneficio_bolsa_familia')}
            onKeyUp={
              (e) => {
                setValue(e.target.name, FormValidate.onChange.Moeda(e.target?.value))
              }
            }
          />
        </div>

        <div className='col-md-3 col-sm-12 mb-3'>
          <label htmlFor='beneficio_bolsa_familia'>R$ Outros</label>
          <input
            className='form-control'
            type='text'
            {...register('beneficio_outros')}
            onKeyUp={
              (e) => {
                setValue(e.target.name, FormValidate.onChange.Moeda(e.target?.value))
              }
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <label htmlFor='beneficio_observacao'>Observação</label>
          <textarea className='form-control' rows='5' {...register('beneficio_observacao')} />
        </div>
      </div>
    </Cards>
  )
}

export { ClienteCadastroBeneficio }
