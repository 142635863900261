import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { FiPlus } from 'react-icons/fi';

import TableWithSubcomponent from '../../components/tableWithSubcomponent';

function ModalAcompanhamentoGrupoAcaoFiltro(props) {
  useEffect(() => {
    if (!props.grupoCarregado) {
      return;
    }

    props.setDadosGrupoTabela(props.dbGrupo)
  }, [props, props.grupoCarregado])

  const addGrupo = dados => {
    props.setGrupoNovo({
      id: dados.id,
      nome: dados.nome
    })
    props.setValue('grupo_acao.id', dados.id)
    props.setValue('grupo_acao.nome', dados.nome)
  }

  const columns = [
    {
      Header: 'Descrição',
      accessor: 'nome'
    },
    {
      Header: 'Ações',
      id: 'deletar',
      accessor: (str) => 'deletar',
      Cell: (tableProps) => (
        <>
          <button className='btn btn-sm btn-outline-success'
            onClick={() => {
              let dataCopy = props.dadosGrupoTabela

              addGrupo(props.dadosGrupoTabela[tableProps.row.index])
              props.setDadosGrupoTabela(dataCopy)
              props.setModalGrupoAcao(false)
            }}
          >
            <FiPlus />
          </button>
        </>
      )
    }
  ];

  if (!props.show) {
    return <></>;
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      id='modalGrupoAcao'
    >
      <Modal.Header custom closeButton >
        <Modal.Title>Grupo de ação</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col tagGrupo'>
            <TableWithSubcomponent columns={columns} data={props.dbGrupo} size={5} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export { ModalAcompanhamentoGrupoAcaoFiltro }
