import React from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { NavbarData } from './navbarData'
import Logo from '../../public/login_logo.svg'

import './navbarStyle.css'
import { useAuth } from '../../services/auth'
import { FiUser } from 'react-icons/fi'

function Menu() {
  const { isConnected, SignOut, SignOutForce, user } = useAuth()
  const perfilId = user.id

  async function handleLogout() { SignOut().catch(error => { SignOutForce() }) }

  if (isConnected) {
    return (
      <Container className='p-0 d-print-none' fluid >
        <Navbar collapseOnSelect className='p-2' expand="md" bg="dark" variant="dark" style={{ zIndex: 15 }}>
          <Link to='/'>
            <Navbar.Brand className='text-left'>
              <img
                src={Logo}
                width='30'
                height='30'
                className='d-inline-block align-top'
                alt='Abba pai church logo'
              />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='me-auto d-flex align-items-start align-items-md-center'>
              {
                NavbarData.map((value, index) => {
                  return (
                    <>
                    {
                    (!value.items) ? 
                      <Link to={value.link} style={{ textDecoration: 'none', color: 'white', margin: '2%' }}>
                        <p><strong>{value.title}</strong></p>
                      </Link> 
                      :
                      <NavDropdown 
                      style={{margin: '2%'}}
                      title={<span><strong>{value.title}</strong></span>} 
                      id="collasible-nav-dropdown">
                        {value.items.map((dropItem, index)=>{
                          return (
                              <>
                              <Link className='dropdown-item' to={dropItem.link} style={{ textDecoration: 'none', color: 'black' }}>
                                {dropItem.title}
                              </Link>
                              {value.items.length !== index +1 ? <NavDropdown.Divider /> : '' }
                              </>
                          )
                        })}
                      </NavDropdown>
                    }
                    </>
                  )
                })
              }
            </Nav>
            <NavDropdown align={{ md: 'end' }} title={<span><FiUser /> {user.nome}</span>} id="collasible-nav-dropdown">
              <Link to={`/perfil/${perfilId}`} className='dropdown-item' style={{ textDecoration: 'none', color: 'black' }}>
                Perfil
              </Link>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => handleLogout()}>Sair</NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
        </Navbar>
      </Container >
    )
  } else {
    return <></>
  }
}

export { Menu }
