import React, { useState, useEffect } from 'react'
import { FiPlus } from 'react-icons/fi';

import { Cards } from '../../components/cards'
import { Tags } from '../../components/tags';
import ModalComposicaoFamiliar from './modalComposicaoFamiliar';

function ClienteCadastroComposicaoFamiliar(props) {
  const [modalComposicaoFamiliar, setModalComposicaoFamiliar] = useState(false)
  const [showDataComposicaoFamiliar, setShowDataComposicaoFamiliar] = useState({})
  const [familiarTag, setFamiliarTag] = useState([])

  useEffect(() => {
    const dadosFamiliar = props.dadosFamiliar.map((value, index) => {
      const strFamiliarTag = JSON.stringify(value);

      const objFamiliarTagBase64 = btoa(strFamiliarTag)
      value.hash = objFamiliarTagBase64

      return value
    })

    setFamiliarTag(dadosFamiliar)
  }, [props.dadosFamiliar])

  const removerFamiliarNovo = index => {
    const novoFamiliar = [...familiarTag]

    novoFamiliar.splice(index, 1)
    setFamiliarTag(novoFamiliar)
  }

  const showModalComposicaoFamiliar = (data) => {
    setShowDataComposicaoFamiliar(data)
    setModalComposicaoFamiliar(true)
  }

  const renderComposicaoFamiliar = () => {
    props.setValue('composicao_familiar', [...familiarTag])
    return (
      familiarTag.map((value, index) => (
        <>
          <Tags
            chave={index}
            nome={value.nome}
            remover={removerFamiliarNovo}
            executeTag={() => showModalComposicaoFamiliar(value)}
            key={index}
          />
        </>
      ))
    )
  }

  return (
    <Cards>
      <section>
        <ModalComposicaoFamiliar
          show={modalComposicaoFamiliar}
          onHide={
            () => {
              setModalComposicaoFamiliar(false);
              setShowDataComposicaoFamiliar({})
            }
          }
          setFamiliarTag={setFamiliarTag}
          familiarTag={familiarTag}
          renderComposicaoFamiliar={renderComposicaoFamiliar}
          showDataModal={showDataComposicaoFamiliar}
          setShowDataModal={setShowDataComposicaoFamiliar}
          register={props.register}
          setValue={props.setValue}
        />
        <div className='row'>
          <div className='col-md-4 col-sm-12 mb-3'>
            <h5>Composição familiar</h5>
          </div>
          <div className='col-md-6 col-sm-12 mb-3'>
            <button
              className='btn btn-outline-success'
              type='button'
              onClick={() => showModalComposicaoFamiliar()}
              style={{ width: '200px' }}
            ><FiPlus /> Adicionar familiar
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            {renderComposicaoFamiliar()}
          </div>
        </div>
      </section>
    </Cards>
  )
}

export { ClienteCadastroComposicaoFamiliar }
