import React, { useState, useMemo, useEffect } from 'react'
import { Container, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FiEdit2, FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import TableWithSubcomponent from '../../components/tableWithSubcomponent'
import { Cards } from '../../components/cards'
import { useAuth } from '../../services/auth'
import UsuarioService from '../../services/usuario'

import { ModalAviso } from '../../components/modalAviso'

function UsuarioLista() {

  const { SignOutForce } = useAuth()

  const [modalAvisoErroServidor, setModalAvisoErroServidor] = useState(false)
  const [modalAvisoAlerta, setModalAvisoAlerta] = useState(false)
  const [messageError, setMessageError] = useState('')

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)


  useEffect(() => {

    async function loadList() {

      setLoading(true)
      UsuarioService.listAll('id,nome,email,status')
        .then(dados => {
          setData(dados.data)
          setLoading(false)
        })
        .catch((error) => {
          if (error.code) {
            switch (error.code) {
              case 0:
                setModalAvisoErroServidor(true)
                setTimeout(() => {
                  setModalAvisoErroServidor(false)
                }, 5000)
                break;
              case 401 || 403:
                SignOutForce()
                window.location.reload()
                break;
              case 409:
                setMessageError(error.message)
                setModalAvisoAlerta(true)
                setTimeout(() => {
                  setModalAvisoAlerta(false)
                }, 3000)
                break;
              default:
                setMessageError(error.message)
                setModalAvisoAlerta(true)
                setTimeout(() => {
                  setModalAvisoAlerta(false)
                }, 3000)
            }
          } else {
            setMessageError(error.message)
            setModalAvisoAlerta(true)
          }
        })
    }

    if (loading) loadList()

  }, [SignOutForce, loading])

  const columns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'nome'
      },
      {
        Header: 'Usuário',
        accessor: 'email'
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Ações',
        id: 'deletar',
        accessor: (str) => 'deletar',

        Cell: (tableProps) => (
          <>
            <>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip id='tooltip-bottom'>
                    Editar
                  </Tooltip>
                }
              >
                <Link
                  className='btn btn-sm btn-outline-success'
                  to={`/usuario/cadastro/${tableProps.row.original.id}`}
                >
                  <FiEdit2 />
                </Link>

              </OverlayTrigger>
            </>
          </>
        )
      }
    ],
    [] //data
  );

  return (
    <>
      <ModalAviso show={modalAvisoAlerta} message={messageError} color='bg-warning' backdrop={false} />
      <ModalAviso show={modalAvisoErroServidor} message='Verifique sua conexão com a internet.' color='bg-danger' backdrop={false} />

      <Container className='col-xl-9 col-lg-11 col-md-12 col-sm-12 mt-5' fluid>
        <Cards>
          <div className='row align-items-center'>
            <div className='col'>
              <h3>Usuários</h3>
            </div>

            <div className='col-auto'>
              <Link className='btn btn-success' to='/usuario/cadastro'><FiPlus /> Novo</Link>
            </div>

          </div>
        </Cards >
        <Cards>
          <TableWithSubcomponent
            columns={columns}
            data={data}
            loading={loading}
          />
        </Cards>
      </Container >
    </>
  )
}

export { UsuarioLista }
