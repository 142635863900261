import React, { useMemo, useState, useEffect } from 'react'
import { Container, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FiEdit2, FiFilter, FiPlus } from 'react-icons/fi'
import { FaCheck } from 'react-icons/fa'
import { SiWhatsapp } from 'react-icons/si'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import TableWithSubcomponent from '../../components/tableWithSubcomponent'
import { Cards } from '../../components/cards'
import { useAuth } from '../../services/auth'
import ClienteService from '../../services/cliente'
import FormValidate from '../../libs/form.validate'

import ModalFiltroCliente from './modalFiltroCliente'
import { setValueForm } from '../../libs/setValueForm'
import FormSanitize from '../../libs/form.sanitize'
import { ModalAviso } from '../../components/modalAviso'

function Cliente() {
  const { register, getValues, reset, setValue } = useForm();
  const { SignOutForce } = useAuth()
  const history = useHistory()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [btnFiltroAtivo, setBtnFiltroAtivo] = useState(false)
  const [modalFiltroCliente, setModalFiltroCliente] = useState(false)
  const [possuiAcompanhamentoGrupo, setPossuiAcompanhamentoGrupo] = useState(false)
  const [showAlertErroServidor, setShowAlertErroServidor] = useState(false)
  const [alertError, setShowAlertError] = useState(false)
  const [carregandoRedirect, setCarregandoRedirect] = useState(false)
  const [showAcompanhamento, setShowAcompanhamento] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [grupoFiltro, setGrupoFiltro] = useState([])

  const [dataInicial, setDataInicial] = useState(searchParams.get('data_inicial'))
  const [dataFinal, setDataFinal] = useState(searchParams.get('data_final'))
  const [status, setStatus] = useState(searchParams.get('status'))
  const [cpf, setCpf] = useState(searchParams.get('cpf'))
  const [bairro, setBairro] = useState(searchParams.get('bairro'))
  const [grupoAcao, setGrupoAcao] = useState(searchParams.get('grupo_acao'))
  const [cliente, setCliente] = useState(searchParams.get('cliente'))
  const [possuiAcompanhamento, setPossuiAcompanhamento] = useState(searchParams.get('possui_acompanhamento'))

  const [filtro, setFiltro] = useState('&')
  const [renderFiltro, setRenderFiltro] = useState(false)

  useEffect(() => {
    setFiltro('&')

    setDataInicial(getValues('data_inicial'))
    setDataFinal(getValues('data_final'))
    setStatus(getValues('status'))
    setCpf(getValues('cpf'))
    setBairro(getValues('bairro'))
    setGrupoAcao(getValues('grupo_acao').nome)
    setCliente(getValues('cliente'))
    setPossuiAcompanhamento(getValues('possui_acompanhamento'))

    if (grupoAcao === 'undefined' || grupoAcao === 'null') {
      setGrupoAcao('')
    }

    if (dataInicial) setFiltro(filtro + `data_inicial=${dataInicial}&`)
    if (dataFinal) setFiltro(filtro + `data_final=${dataFinal}&`)
    if (status) setFiltro(filtro + `status=${status}&`)
    if (cpf) setFiltro(filtro + `cpf=${cpf}&`)
    if (bairro) setFiltro(filtro + `bairro=${bairro}&`)
    if (grupoAcao) setFiltro(filtro + `grupo_acao=${grupoAcao}&`)
    if (cliente) setFiltro(filtro + `cliente=${cliente}&`)
    if (possuiAcompanhamento) setFiltro(filtro + `possui_acompanhamento=${possuiAcompanhamento}&`)

    setRenderFiltro(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderFiltro])

  useEffect(() => {

    setGrupoFiltro({
      id: searchParams.get('grupo_acao'),
      nome: searchParams.get('grupo')
    })

    if (!carregandoRedirect) {

      async function loadList() {
        setLoading(true)

        function handleErrors(error) {
          if (error.code) {
            switch (error.code) {
              case 0:
                setShowAlertErroServidor(true)
                setLoading(false)
                setTimeout(() => {
                  setShowAlertErroServidor(false)
                }, 5000)
                break
              case 403:
                SignOutForce()
                window.location.reload()
                break
              case 409:
                setMessageError(error.message)
                setShowAlertError(true)
                setLoading(false)
                setTimeout(() => {
                  setShowAlertError(false)
                  setLoading(false)
                }, 3000)
                break
              default:
                setMessageError(error.message)
                setShowAlertError(true)
                setTimeout(() => {
                  setShowAlertError(false)
                  setLoading(false)
                }, 3000)
                break
            }
          } else {
            setMessageError(error.message)
            setShowAlertError(true)
            setLoading(false)
            setTimeout(() => {
              setShowAlertError(false)
            }, 4000)
          }
        }

        try {
          if (location.search) {
            const strDataInicial = searchParams.get('data_inicial')
            const strDataFinal = searchParams.get('data_final')
            const strPessoa = searchParams.get('cpf')
            const strPessoaBairro = searchParams.get('bairro')
            let grupoAcao = searchParams.get('grupo_acao')
            let grupoNome = searchParams.get('grupo')
            const strStatus = searchParams.get('status')
            const strPossuiAcompanhamento = searchParams.get('possui_acompanhamento')

            if (grupoAcao === 'undefined' || grupoAcao === 'null') {
              grupoAcao = ''
              grupoNome = ''
            }

            if (strPossuiAcompanhamento) {
              setPossuiAcompanhamentoGrupo(true)
              setShowAcompanhamento(true)
            }

            if (grupoAcao) setShowAcompanhamento(true)

            await handleFilter({
              data_inicial: strDataInicial,
              data_final: strDataFinal,
              status: strStatus,
              cpf: strPessoa,
              bairro: strPessoaBairro,
              grupo_acao: grupoAcao,
              possui_acompanhamento: strPossuiAcompanhamento
            });

            setValueForm(setValue, {
              data_inicial: strDataInicial,
              data_final: strDataFinal,
              status: strStatus,
              pessoa: {
                nome: searchParams.get('cliente'),
                cpf: FormValidate.onChange.CPF(strPessoa),
                bairro: searchParams.get('bairro'),
              },
              grupo_acao: {
                id: grupoAcao,
                nome: grupoNome
              },
              possui_acompanhamento: strPossuiAcompanhamento
            })
          } else {
            ClienteService.listAll('nome,id,nome,telefone,status,visitador_visita_realizada,beneficio_doacao,beneficio_bolsa_familia,beneficio_outros,emprego_renda,composicao_familiar,composicao_familiar.emprego_renda&sort=id')
              .then(dados => {
                setData(dados.data)
                setLoading(false)
              })
              .catch(error => {
                handleErrors(error)
                setLoading(false)
              })
          }
        } catch (error) {
          handleErrors(error)
          setLoading(false)
        }
      }

      if (loading) {
        loadList()
      }

      setCarregandoRedirect(!carregandoRedirect)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carregandoRedirect, loading])

  const columns = useMemo(
    () => [
      {
        id: 'nome',
        Header: () => (
          <div style={{ minWidth: '250px' }}>Nome</div>
        ),
        accessor: 'nome'
      },
      {
        id: 'composicao_familiar',
        Header: () => (
          <div style={{ textAlign: 'center', width: '83px' }}>Familiares</div>
        ),
        accessor: dados => {
          return dados.composicao_familiar.length
        },
        Cell: ({ value }) => {
          return <div style={{ textAlign: 'center', width: '83px' }}>{value}</div>
        }
      },
      {
        id: 'emprego_renda',
        Header: () => (
          <div style={{ textAlign: 'center', width: '100px' }}>R$ Renda</div>
        ),
        accessor: dados => {
          const valorEmprego = dados.emprego_renda
          let valorComposicaoFamilia = 0
          dados.composicao_familiar.forEach((item) => {
            valorComposicaoFamilia = valorComposicaoFamilia + item.emprego_renda
          })
          const valorBeneficio = dados.beneficio_doacao + dados.beneficio_bolsa_familia + dados.beneficio_outros
          return valorEmprego + valorComposicaoFamilia + valorBeneficio
        },
        Cell: ({ value, row }) => {
          return <div style={{ textAlign: 'center', width: '100px' }}>{value.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
        }
      },
      {
        id: 'telefone',
        Header: () => (
          <div style={{ textAlign: 'center', width: '148px' }}>Telefone</div>
        ),
        accessor: dadosPorLinhaTelefone => {
          return <div style={{ textAlign: 'center', width: '148px' }}>{FormValidate.onChange.Telefone(dadosPorLinhaTelefone.telefone)}</div>
        }
      },
      {
        id: 'Visita Realizada',
        Header: () => (
          <div style={{ textAlign: 'center', width: '78px' }}>Visita Realizada</div>
        ),
        accessor: dadosStatus => {
          return <div style={{ textAlign: 'center', width: '78px' }}>{(dadosStatus.visitador_visita_realizada) ? <FaCheck /> : ''}</div>
        }
      },
      {
        id: 'status',
        Header: () => (
          <div style={{ textAlign: 'center', width: '99px' }}>Status</div>
        ),
        accessor: dadosStatus => {
          return dadosStatus.status
        },
        Cell: ({ value, row }) => {
          return <div style={{ textAlign: 'center', width: '99px' }}>{value}</div>
        }
      },
      {
        id: 'acoes',
        Header: () => (
          <div style={{ textAlign: 'center', width: '82px' }}>Ações</div>
        ),
        accessor: 'telefone',
        Cell: (tableProps) => (
          <div className='align-center text-center'>
            {
              tableProps.value
                ? (
                  <>
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip id='tooltip-bottom'>
                          Abrir whatsapp
                        </Tooltip>
                      }
                    >
                      <a className='btn btn-sm btn-outline-success' target='_blank' rel='noreferrer' href={`https://web.whatsapp.com/send?phone=+55${tableProps.value}`}>
                        <SiWhatsapp />
                      </a>

                    </OverlayTrigger>
                  </>
                )
                : (<></>)
            }&nbsp;

            <>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip id='tooltip-bottom'>
                    Editar
                  </Tooltip>
                }
              >

                <Link
                  className='btn btn-sm btn-outline-success'
                  to={`/cliente/cadastro/${tableProps.row.original.id}`}
                >
                  <FiEdit2 />
                </Link>

              </OverlayTrigger>
            </>
            &nbsp;
          </div>
        )
      }
    ],
    []
  );

  const cleanFilter = () => {
    reset()
    setBtnFiltroAtivo(false)
    window.location.assign(location.pathname)
  }

  const handleFilter = async data => {
    if (!data?.bairro) data.bairro = '';
    if (!data?.cpf) data.cpf = '';
    if (!data?.data_inicial) data.data_inicial = '';
    if (!data?.data_final) data.data_final = '';
    if (!data?.grupo_acao) data.grupo_acao = '';
    if (!data?.possui_acompanhamento) data.possui_acompanhamento = '';
    if (!data?.status) data.status = 'ativado';

    if (data?.data_inicial && !data?.data_final) {
      data.data_final = FormSanitize.DateHourNow().dateNow
      setValue('data_final', data.data_final)
    }

    data.fields = 'id,nome,telefone,status,visitador_visita_realizada,beneficio_doacao,beneficio_bolsa_familia,beneficio_outros,composicao_familiar,emprego_renda,composicao_familiar.emprego_renda'

    ClienteService
      .filterAll(data)
      .then(dadosFiltrados => {
        setData(dadosFiltrados.data)
        setModalFiltroCliente(false)
        setBtnFiltroAtivo(true)
        setLoading(false)
      })
      .catch(error => {
        if (error.code) {
          switch (error.code) {
            case 0:
              setShowAlertErroServidor(true)
              setLoading(false)
              setTimeout(() => {
                setShowAlertErroServidor(false)
              }, 5000)
              break
            case 401 || 403:
              SignOutForce()
              window.location.reload()
              break
            case 409:
              setMessageError(error.message)
              setShowAlertError(true)
              setLoading(false)
              setTimeout(() => {
                setShowAlertError(false)
                setLoading(false)
              }, 3000)
              break
            default:
              setMessageError(error.message)
              setShowAlertError(true)
              setTimeout(() => {
                setShowAlertError(false)
                setLoading(false)
              }, 3000)
              break
          }
        } else {
          setMessageError(error.message)
          setShowAlertError(true)
          setTimeout(() => {
            setShowAlertError(false)
            setLoading(false)
          }, 3000)
        }
      })
  }

  function limparFiltros() {
    setValue('data_inicial', '')
    setValue('data_final', '')
    setValue('status', '')
    setValue('cpf', '')
    setValue('bairro', '')
    setValue('grupo_acao', '')
    setValue('grupo', '')
    setValue('cliente', '')
    setValue('possui_acompanhamento', '')

    history.push('/cliente')
  }

  return (
    <div>
      <ModalAviso show={showAlertErroServidor} message='Verifique sua conexão com a internet.' color='bg-danger' backdrop={false} />
      <ModalAviso show={alertError} message={messageError} color='bg-warning' backdrop={false} />
      <Container className='col-xl-9 col-lg-11 col-md-12 col-sm-12 mt-5' fluid>
        <form>
          <ModalFiltroCliente
            show={modalFiltroCliente}

            onHide={() => {
              setModalFiltroCliente(false)
              setCarregandoRedirect(false)
              setLoading(true)
              setRenderFiltro(true)
            }}

            register={register}
            setValue={setValue}
            getValues={getValues}
            cleanFilter={cleanFilter}
            setPossuiAcompanhamentoGrupo={setPossuiAcompanhamentoGrupo}
            possuiAcompanhamentoGrupo={possuiAcompanhamentoGrupo}
            setCarregandoRedirect={setCarregandoRedirect}
            carregandoRedirect={carregandoRedirect}
            grupoFiltro={grupoFiltro}
            setGrupoFiltro={setGrupoFiltro}
            showAcompanhamento={showAcompanhamento}
            setShowAcompanhamento={setShowAcompanhamento}
            onSubmit=''
          />
        </form>
        <Cards>
          <div className='row align-items-center'>
            <div className='col'>
              <h3>Cliente</h3>
            </div>

            <div className='col-auto'>
              <button
                className={'btn ' + (btnFiltroAtivo ? 'btn-outline-dark' : 'btn-outline-secondary')}
                type='button'
                onClick={() => { setModalFiltroCliente(true) }}
              ><FiFilter /> Filtrar clientes
              </button>

              <button
                className={'btn ' + (btnFiltroAtivo ? 'btn-outline-dark' : 'btn-outline-secondary')}
                style={{ marginLeft: 6 }}
                type='button'
                onClick={() => {
                  limparFiltros()
                  setCarregandoRedirect(false)
                  setLoading(true)
                  setRenderFiltro(true)
                }}
              ><FiFilter /> Limpar filtro
              </button>
              &nbsp;
              <Link className='btn btn-success' to='/cliente/cadastro'><FiPlus /> Novo</Link>
            </div>
          </div>
        </Cards>
        <Cards>
          <TableWithSubcomponent
            columns={columns}
            data={data}
            loading={loading}
          />
        </Cards>
      </Container>
    </div>
  )
}

export { Cliente }
