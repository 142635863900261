import React, { useState, useEffect, createContext, useContext } from 'react'

import AsyncStorage from '../storage/asyncStorage'
import LoginService from '../services/login'
import API from '../config/api'

const DEFAULT = {
  SignIn: () => { },
  SignOut: () => { },
  SignOutForce: () => { },
  isConnected: false,
  user: {},
  isLoading: true,
}

const AuthContext = createContext(DEFAULT)

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const [isConnected, setConnected] = useState(false)
  const [isLoading, setLoading] = useState(true)

  API.axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
  API.axios.defaults.headers.common['Content-Encoding'] = 'utf-8';

  useEffect(() => {
    async function loadStorageData() {
      const storageToken = localStorage.getItem('@LMAuthAbba:token')

      try {
        if (storageToken) {
          API.axios.defaults.headers['Authorization'] = `Bearer ${storageToken}`
          await Me().then(dados => {
            setConnected(true)
          }).catch(async (error) => {
          })
        }

      } catch (error) {
      }

      setLoading(false)
    }

    loadStorageData()
  }, [])

  function SignIn(usuario, senha) {
    return new Promise((resolve, reject) => {
      LoginService.login({ email: usuario, senha })
        .then(async dados => {
          await AsyncStorage.setItem('@LMAuthAbba:token', dados.access_token)

          const token = dados.access_token

          API.axios.defaults.headers['Authorization'] = `Bearer ${token}`

          await Me()

          setConnected(true)

          return resolve(dados)
        })
        .catch(error => { return reject(error) })
    })
  }

  async function SignOut() {
    return new Promise((resolver, reject) => {
      LoginService.logout()
        .then(dados => {
          AsyncStorage.removeItem('@LMAuthAbba:token')
            .then(() => {
              setUser('')
              setConnected(false)

              const { data } = dados

              return resolver(data)
            })
        })
        .catch(error => { return reject(error) })
    })
  }

  async function SignOutForce() {
    AsyncStorage.removeItem('@LMAuthAbba:token')
      .then(() => {
        setUser('')
        setConnected(false)
      })
  }

  async function Me() {
    return new Promise((resolve, reject) => {
      LoginService.me()
        .then(async dados => {
          setUser(dados)
          return resolve(dados)
        })
        .catch(error => { return reject(error) })
    })
  }

  return (
    <AuthContext.Provider value={{ SignIn, SignOut, SignOutForce, isConnected, user, isLoading }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
