const FormSanitize = {}

FormSanitize.Sanitize = value => {
  return value?.replace(/\D/g, '')
}

FormSanitize.DateHourNow = () => {
  let objDateNow = new Date()
  let objHourNow = objDateNow.getHours()
  let objMinuteNow = objDateNow.getMinutes()

  objHourNow = ('0' + objHourNow).slice(-2) //adiciona um zero se a hora tiver menos de 2 dígitos
  objMinuteNow = ('0' + objMinuteNow).slice(-2) //adiciona zero se o minuto tiver menos de 2 dígitos

  objDateNow = objDateNow.toISOString().slice(0, 10);

  return {
    dateNow: objDateNow,
    hourNow: objHourNow + ':' + objMinuteNow
  };
}

export default FormSanitize 