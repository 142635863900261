import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FiFilter, FiMinus, FiPlus, FiSearch, FiTrash2 } from 'react-icons/fi'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import { ModalAcompanhamentoGrupoAcaoFiltro } from '../AcompanhamentoBeneficio2/modalAcompanhamentoGrupoAcaoFiltro'

import GrupoAcaoService from '../../services/grupoAcao'
import ClienteService from '../../services/cliente'
import FormSanitize from '../../libs/form.sanitize'
import FormValidate from '../../libs/form.validate'
import { ModalAviso } from '../../components/modalAviso'

function ModalFiltroCliente(props) {
  const [modalGrupoAcao, setModalGrupoAcao] = useState(false)
  const [dadosGrupoTabela, setDadosGrupoTabela] = useState([])
  const [dbGrupo, setDbGrupo] = useState([])
  const [grupoCarregado, setGrupoCarregado] = useState(false)
  const [possuiAcompanhamento, setPossuiAcompanhamento] = useState(false)
  const [showAlertErroServidor, setShowAlertErroServidor] = useState(false)
  const [alertError, setShowAlertError] = useState(false)
  const [messageError, setMessageError] = useState('')

  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const redirectUrl = (event) => {
    event.preventDefault()

    const objDataInicial = props.getValues('data_inicial')
    let objDataFinal = props.getValues('data_final')
    const objPessoa = FormSanitize.Sanitize(props.getValues('pessoa.cpf'))
    const objPessoaNome = props.getValues('pessoa.nome')
    const objBairro = props.getValues('pessoa.bairro')
    const objStatus = props.getValues('status')
    let objPossuiAcompanhamento = props.getValues('possui_acompanhamento')
    const objGrupoAcao = props.getValues('grupo_acao.id')
    const objGrupo = props.getValues('grupo_acao.nome')

    if (objDataInicial && !objDataFinal) {
      objDataFinal = FormSanitize.DateHourNow().dateNow
    }

    if (!objPossuiAcompanhamento || (!possuiAcompanhamento && !props.possuiAcompanhamentoGrupo)) {
      objPossuiAcompanhamento = ''
    }

    if (!props.carregandoRedirect) {
      props.setCarregandoRedirect(!props.carregandoRedirect)
      props.setGrupoFiltro({
        id: objGrupoAcao,
        nome: objGrupo
      })
    }

    let filtro = '&';
    if (objDataInicial) filtro = filtro + `data_inicial=${objDataInicial}&`
    if (objDataFinal) filtro = filtro + `data_final=${objDataFinal}&`
    if (objStatus) filtro = filtro + `status=${objStatus}&`
    if (objPessoa) filtro = filtro + `cpf=${objPessoa}&`
    if (objBairro) filtro = filtro + `bairro=${objBairro}&`
    if (objGrupoAcao) filtro = filtro + `grupo_acao=${objGrupoAcao}&`
    if (objGrupo) filtro = filtro + `grupo=${objGrupo}&`
    if (objPessoaNome) filtro = filtro + `cliente=${objPessoaNome}&`
    if (objPossuiAcompanhamento) filtro = filtro + `possui_acompanhamento=${objPossuiAcompanhamento}&`

    history.push('/cliente?' + filtro)
    props.onHide()
  }

  function handleErrors(error) {
    if (error.code) {
      switch (error.code) {
        case 0:
          setShowAlertErroServidor(true)
          setTimeout(() => {
            setShowAlertErroServidor(false)
          }, 5000)
          break
        case 403:
          window.location.reload()
          break
        case 409:
          setMessageError(error.message)
          setShowAlertError(true)
          setTimeout(() => {
            setShowAlertError(false)
          }, 3000)
          break
        default:
          setMessageError(error.message)
          setShowAlertError(true)
          setTimeout(() => {
            setShowAlertError(false)
          }, 3000)
          break
      }
    } else {
      setMessageError(error.message)
      setShowAlertError(true)
      setTimeout(() => {
        setShowAlertError(false)
      }, 4000)
    }
  }

  const handlePessoa = () => {
    const cpf = FormSanitize.Sanitize(props.getValues('pessoa.cpf'))

    if (!cpf) {
      props.setValue('pessoa.nome', '')
      return
    }

    ClienteService
      .listCPF(cpf)
      .then(dados => {
        if (dados.data.length > 0) {
          if (dados.data[0].cpf) {
            props.setValue('pessoa.nome', dados.data[0].nome)
          }
        } else {
          setMessageError('Cliente não encontrado com este CPF!')
          setShowAlertError(true)
          setTimeout(() => {
            setShowAlertError(false)
          }, 2000)
        }
      })
      .catch(error => {
        handleErrors(error)
      })
  }

  const handleGrupoAcao = () => {
    setModalGrupoAcao(true)

    if (searchParams.get('possui_acompanhamento') !== '') {
      setPossuiAcompanhamento(false)
      props.setPossuiAcompanhamentoGrupo(false)
    }

    GrupoAcaoService
      .listAllActive('id,nome')
      .then(dados => {
        setDbGrupo(dados.data)
        setGrupoCarregado(true)
      })
      .catch(error => {
        handleErrors(error)
      })
  }

  const handleGrupoAcaoCancelar = () => {
    props.setGrupoFiltro([]);
    props.setValue('grupo_acao.id', '')
    props.setValue('grupo_acao.nome', '')

    if (searchParams.get('possui_acompanhamento') !== '') {
      setPossuiAcompanhamento(false)
      props.setPossuiAcompanhamentoGrupo(false)
    }
  }

  if (!props.carregandoRedirect) {
    props.setCarregandoRedirect(!props.carregandoRedirect)
  }

  return (
    <>
      <ModalAviso show={showAlertErroServidor} message='Verifique sua conexão com a internet.' color='bg-danger' backdrop={false} />
      <ModalAviso show={alertError} message={messageError} color='bg-warning' backdrop={false} />
      <ModalAcompanhamentoGrupoAcaoFiltro
        show={modalGrupoAcao}
        onHide={() => { setModalGrupoAcao(false) }}
        setGrupoNovo={props.setGrupoFiltro}
        grupoNovo={props.grupoFiltro}
        dbGrupo={dbGrupo}
        dadosGrupoTabela={dadosGrupoTabela}
        setDadosGrupoTabela={setDadosGrupoTabela}
        grupoCarregado={grupoCarregado}
        setModalGrupoAcao={setModalGrupoAcao}
        setValue={props.setValue}
      />
      <Modal {...props} size='lg' centered>
        <Modal.Header closeButton>
          <Modal.Title>Filtrar clientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={props.onSubmit}>
            <div className='row'>
              <div className='col-sm-12 col-md-4 mb-3'>
                <div className='form-grop'>
                  <label htmlFor='data_inicial'>Data inicial</label>
                  <input className='form-control' id='data_inicial' type='date' {...props.register('data_inicial')} />
                </div>
              </div>
              <div className='col-sm-12 col-md-4 mb-3'>
                <div className='form-group'>
                  <label htmlFor='data_final'>Data final</label>
                  <input className='form-control' id='data_final' type='date' {...props.register('data_final')} />
                </div>
              </div>
              <div className='col-sm-12 col-md-4 mb-3'>
                <div className='form-group'>
                  <label htmlFor='status'>Status</label>
                  <select className='form-select' id='status' {...props.register('status')}>
                    <option value='ativado'>Ativado</option>
                    <option value='cancelado'>Cancelado</option>
                    <option value='bloqueado'>Bloqueado</option>
                    <option value=' '>Todos</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-sm-12 col-md-12 mb-3'>
                <label htmlFor='grupo_acao'>Grupo de ação</label>
                <div className='input-group'>
                  <input className='form-control' id='grupo_acao' type='text' {...props.register('grupo_acao.nome')} readOnly />
                  <button
                    className='btn btn-outline-success'
                    id="btnFiltroGrupo"
                    type='button'
                    title='Adicionar grupo de ação'
                    onClick={() => { handleGrupoAcao(); props.setShowAcompanhamento(true) }}
                  >
                    <FiPlus />
                  </button>
                  <button
                    className='btn btn-outline-danger'
                    id='btnFiltroGrupoLimpa'
                    type='button'
                    title='Limpar filtro'
                    onClick={() => { handleGrupoAcaoCancelar(); props.setShowAcompanhamento(false) }}
                  >
                    <FiMinus />
                  </button>
                </div>
              </div>
              <div className='col-auto mb-3' style={{ display: (props.showAcompanhamento) ? 'block' : 'none' }}>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    id='booPossuiAcompanhamento'
                    type='checkbox'
                    value={possuiAcompanhamento}
                    checked={props.possuiAcompanhamentoGrupo ?? possuiAcompanhamento}
                    defaultChecked={false}
                    onChange={() => {
                      props.setPossuiAcompanhamentoGrupo(!props.possuiAcompanhamentoGrupo)

                      if (props.possuiAcompanhamentoGrupo) {
                        setPossuiAcompanhamento(!!possuiAcompanhamento)
                      }
                    }}
                  />
                  <label className='form-check-label' htmlFor='booPossuiAcompanhamento'>
                    Possui acompanhamento
                  </label>
                </div>
              </div>
              {
                possuiAcompanhamento || props.possuiAcompanhamentoGrupo
                  ? (
                    <>
                      <div className='col-auto mb-3' style={{ display: (props.showAcompanhamento) ? 'block' : 'none' }} >
                        <div className='form-group'>
                          <select className='form-select' id='txtPossuiAcompanhamento' {...props.register('possui_acompanhamento')} >
                            <option value='sim'>Sim</option>
                            <option value='nao'>Não</option>
                          </select>
                        </div>
                      </div>
                    </>
                  )
                  : <></>
              }
            </div>
            <div className='row'>
              <div className='col-sm-12 col-md-4 mb-3'>
                <div className='form-group'>
                  <label htmlFor='cliente'>CPF</label>
                  <div className='input-group'>
                    <input
                      className='form-control'
                      id="txtPessoaCpf"
                      name='cpf'
                      type='text'
                      autoComplete='off'
                      onKeyPress={(e) => {
                        if (e.charCode === 13) {
                          e.preventDefault()
                          e.stopPropagation()
                          handlePessoa(e.target.value)
                        }
                      }}
                      {...props.register('pessoa.cpf', {
                        ...FormValidate.Require,
                        ...FormValidate.MinLength(11),
                        ...FormValidate.MaxLength(14),
                        ...FormValidate.CPF,
                      })}
                      onChange={(e) => props.setValue(e.target.name, FormValidate.onChange.CPF(e.target.value))}
                    />

                    <button
                      className='btn btn-outline-secondary'
                      id="btnFiltroCliente"
                      type='button'
                      onClick={() => { handlePessoa() }}
                      title='Pesquisar CPF'
                    >
                      <FiSearch />
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-8 mb-3'>
                <label htmlFor='pessoa_nome'>Cliente</label>
                <input className='form-control' id='pessoa_nome' type='text' readOnly {...props.register('pessoa.nome')} />
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-12 col-md-12'>
                <label htmlFor='pessoa_bairro'>Bairro</label>
                <div className='input-group'>
                  <input className='form-control' id='bairro' type='text' {...props.register('pessoa.bairro')} />
                </div>
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-outline-secondary' onClick={props.cleanFilter}>
            <FiTrash2 /> Limpar filtro
          </button>
          <button className='btn btn-primary' onClick={(e) => { redirectUrl(e) }}>
            <FiFilter /> Filtrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalFiltroCliente
