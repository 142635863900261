import React from 'react'

import { CampoObrigatorio } from '../../components/campoObrigatorio'
import { Cards } from '../../components/cards'
import FormValidate from '../../libs/form.validate'

function ClienteCadastroCliente({ register, errors, setValue, status, clienteId }) {
  return (
    <section>
      <Cards>
        <div className='row'>
          <div className='col-md-9 col-sm-12 mb-3'>
            <label htmlFor='nome'>Nome completo <CampoObrigatorio /></label>
            <input className='form-control' type='text' {...register('nome', { required: 'Informe o nome completo!' })} autoFocus />
            <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.nome?.message}</span>
          </div>

          <div className='col-md-3 col-cm-12 mb-3'>
            <div className='form-group'>
              <label htmlFor='membro'>Membro <CampoObrigatorio /></label>
              <select
                className='form-select'
                {...register('membro', {
                  validate: {
                    selectOne: (v) => v !== '' || 'Informe se é membro sim ou não',
                  },
                })}
              >
                <option value={false}>Não</option>
                <option value={true}>Sim</option>
              </select>
              <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.membro?.message}</span>
            </div>
          </div>

        </div>

        <div className='row'>
          <div className='col-md-9 col-sm-12 mb-3'>
            <label htmlFor='email'>E-mail</label>
            <input className='form-control' type='email' {...register('email')} />
            <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.email?.message}</span>
          </div>

          <div className='col-md-3 col-sm-12 mb-3'>
            <div className='form-group'>
              <label htmlFor='data_nascimento'>Data de nascimento <CampoObrigatorio /></label>
              <input type='date' className='form-control' {...register('data_nascimento', { required: 'Informe a data de nascimento.' })} />
              <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.data_nascimento?.message}</span>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-4 col-sm-12 mb-3'>
            <label htmlFor='cpf'>CPF <CampoObrigatorio /></label>
            <input
              className='form-control'
              type='text'
              name='cpf'
              {...register('cpf', {
                ...FormValidate.Require,
                ...FormValidate.MinLength(11),
                ...FormValidate.MaxLength(14),
                ...FormValidate.CPF,
              })}
              onChange={(e) => setValue(e.target.name, FormValidate.onChange.CPF(e.target.value))}
            />
            <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.cpf?.message}</span>
          </div>

          <div className='col-md-4 col-sm-12 mb-3'>
            <label htmlFor='rg'>RG</label>
            <input
              className='form-control'
              type='text'
              name='rg'
              {...register('rg')}
            />
          </div>

          <div className='col-md-4 col-sm-12 mb-3'>
            <label htmlFor='estado_civil'>Estado civil <CampoObrigatorio /></label>
            <select
              className='form-select'
              {...register('estado_civil', {
                validate: {
                  selectOne: (v) => v !== '' || 'Selecione um estado civil!',
                },
              })}
            >
              <option value=''>Selecione</option>
              <option value='CASADO'>Casado(a)</option>
              <option value='DIVORCIADO'>Divorciado(a)</option>
              <option value='SOLTEIRO'>Solteiro(a)</option>
              <option value='UNIAO_ESTAVEL'>União estável</option>
              <option value='VIUVO'>Viúvo(a)</option>
            </select>
            <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.estado_civil?.message}</span>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-4 col-sm-12 mb-3'>
            <label htmlFor='telefone'>Telefone <CampoObrigatorio /></label>
            <input
              className='form-control'
              name='telefone'
              type='phone'
              {...register('telefone', { required: 'Telefone obrigatório!' })}
              onChange={(e) => setValue(e.target.name, FormValidate.onChange.Telefone(e.target.value))}
            />
            <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.telefone?.message}</span>
          </div>
          <div className='col-md-4 col-sm-12 mb-3'>
            <label htmlFor='telefone'>Telefone 2</label>
            <input
              className='form-control'
              type='text'
              {...register('telefone2')}
              onChange={(e) => setValue(e.target.name, FormValidate.onChange.Telefone(e.target.value))}
            />
          </div>
          <div className='col-md-4 col-sm-12 mb-3'>
            <label htmlFor='telefone'>Telefone 3</label>
            <input
              className='form-control'
              type='text'
              {...register('telefone3')}
              onChange={(e) => setValue(e.target.name, FormValidate.onChange.Telefone(e.target.value))}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6 col-sm-12 mb-3'>
            <div className='form-group'>
              <label htmlFor='visitador'>Visitador</label>
              <input className='form-control' type='text' {...register('visitador')} />
              <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.visitador?.message}</span>
            </div>
          </div>

          <div className='col-md-3 col-sm-12 mb-3'>
            <div className='form-group'>
              <label htmlFor='visitador_data_visita'>Data da Visita </label>
              <input type='date' className='form-control' {...register('visitador_data_visita')} />
              <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.visitador_data_visita?.message}</span>
            </div>
          </div>

          <div className='col-md-3 col-sm-12'>
            <div className='form-group'>
              <label htmlFor='membro'>Visita Realizada</label>
              <select
                className='form-select'
                {...register('visitador_visita_realizada', { validate: { selectOne: (v) => v !== '' || 'Informe se é membro sim ou não', }, })}
              >
                <option value={false}>Não</option>
                <option value={true}>Sim</option>
              </select>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-3 col-sm-12 mb-3' style={status ? { display: 'block' } : { display: 'none' }} >
            <div className='form-group'>
              <label htmlFor='status'>Status</label>
              <select
                className='form-select'
                {...register('status')}
              >
                <option value='ATIVADO'>ATIVADO</option>
                <option value='BLOQUEADO'>BLOQUEADO</option>
                <option value='CANCELADO'>CANCELADO</option>
              </select>
              <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.status?.message}</span>
            </div>
          </div>

          <div className='col-md-9 col-sm-12 mb-3'>
            <label htmlFor='status_observacao'>Status observação</label>
            <input className='form-control' type='text' {...register('status_observacao')} />
          </div>
        </div>



      </Cards>
    </section>
  )
}

export { ClienteCadastroCliente }
