import React, { useEffect, useState } from 'react'
import { Button, Container, Spinner, Navbar } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FiArrowLeft, FiCheck } from 'react-icons/fi'
import { useParams } from 'react-router-dom'

import { ClienteCadastroCliente } from './clienteCadastroCliente'
import { ClienteCadastroGrupoAcao } from './clienteCadastroGrupoAcao'
import { ClienteCadastroEscolaridade } from './clienteCadastroEscolaridade'
import { ClienteCadastroBeneficio } from './clienteCadastroBeneficio'
import { ClienteCadastroMoradia } from './clienteCadastroMoradia'
import { ClienteCadastroEndereco } from './clienteCadastroEndereco'
import { ClienteCadastroHistoricoFamiliar } from './clienteCadastroHistoricoFamiliar'
import { ClienteCadastroVeiculo } from './clienteCadastroVeiculo'
import { ClienteCadastroComposicaoFamiliar } from './clienteCadastroComposicaoFamiliar'
import { ClienteCadastroResponsavel } from './clienteCadastroResponsavel'
import { ClienteCadastroUltimaAtualizacao } from './clienteCadastroUltimaAtualizacao'

import { setValueForm } from '../../libs/setValueForm';
import FormValidate from '../../libs/form.validate'
import ClienteService from '../../services/cliente'
import GrupoAcaoService from '../../services/grupoAcao'
import cliente from '../../services/cliente'
import { useAuth } from '../../services/auth'
import { ModalAviso } from '../../components/modalAviso'
import RegisterSanitize from './libsCliente/formRegister.sanitize'
import { ClienteCadastroEmprego } from './clienteCadastroEmprego'
import { ClienteCadastroFotos } from './clienteCadastroFotos'

function ClienteCadastro() {
  const { register, formState: { errors }, setValue, reset, getValues, handleSubmit } = useForm()
  const { SignOutForce } = useAuth()
  const { clienteId } = useParams()
  const [dataGrupoAcao, setDataGrupoAcao] = useState([])
  const [dataComposicaoFamiliar, setDataComposicaoFamiliar] = useState([])
  const [dadoCarregado, setDadoCarregado] = useState(false)
  const [dadoGrupoAcao, setDadoGrupoAcao] = useState([])
  const [status, setStatus] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalAviso, setModalAviso] = useState(false)
  const [showAlertErroServidor, setShowAlertErroServidor] = useState(false)
  const [alertError, setShowAlertError] = useState(false)
  const [carregandoPagina, setCarregandoPagina] = useState(true)
  const [messageError, setMessageError] = useState('')
  let strModalMensagem = 'Registro salvo com sucesso!'

  const [files, setFiles] = useState([]);
  const [allDeletedFiles, setAllDeletedFiles] = useState([]);



  useEffect(() => {
    if (!clienteId) {
      GrupoAcaoService
        .listAllActive()
        .then(dados => {
          setDataGrupoAcao(dados.data)
          setCarregandoPagina(false)
        })
        .catch(error => { window.location.reload() })
      return;
    }

    async function loadData() {
      try {
        setStatus(true)
        ClienteService
          .list(clienteId)
          .then(async (data) => {
            setValueForm(setValue, data)

            let grupoAcaoService = await GrupoAcaoService.listAllActive()
            grupoAcaoService = grupoAcaoService.data

            setValue('grupo_acao', data.grupo_acao)
            setValue('telefone', FormValidate.onChange.Telefone(data.telefone))
            setValue('telefone2', FormValidate.onChange.Telefone(data.telefone2))
            setValue('telefone3', FormValidate.onChange.Telefone(data.telefone3))
            setValue('cpf', FormValidate.onChange.CPF(data.cpf))
            //emprego
            setValue('emprego_renda', data.emprego_renda.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            //beneficios
            setValue('beneficio_doacao', data.beneficio_doacao.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            setValue('beneficio_bolsa_familia', data.beneficio_bolsa_familia.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            setValue('beneficio_outros', data.beneficio_outros.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            //moradia
            setValue('moradia_gasto_aluguel', data.moradia_gasto_aluguel.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            setValue('moradia_gasto_agua', data.moradia_gasto_agua.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            setValue('moradia_gasto_energia', data.moradia_gasto_energia.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            setValue('moradia_gasto_gas', data.moradia_gasto_gas.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            setValue('moradia_gasto_alimentacao', data.moradia_gasto_alimentacao.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            setValue('moradia_gasto_remedio', data.moradia_gasto_remedio.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            setValue('moradia_gasto_internet', data.moradia_gasto_internet.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            setValue('moradia_gasto_educacao', data.moradia_gasto_educacao.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            setValue('moradia_gasto_outros', data.moradia_gasto_outros.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            //veiculo
            setValue('veiculo_parcela', data.veiculo_parcela.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            //usuario
            setValue('usuario_cadastro.status', data.usuario_cadastro.status)
            setValue('usuario_atualizacao.status', data.usuario_atualizacao.status)

            function comparer(otherArray) {
              return function (current) {
                return otherArray.filter(function (other) {
                  return other.id === current.id
                }).length === 0;
              }
            }

            const grupoAcao = data.grupo_acao

            let apenasGrupoService = grupoAcaoService.filter(comparer(grupoAcao));
            let apenasGrupoAcao = grupoAcao.filter(comparer(grupoAcaoService));
            let categoriasFiltradas = apenasGrupoService.concat(apenasGrupoAcao);

            setCarregandoPagina(false)
            setDataGrupoAcao(categoriasFiltradas)
            setDadoGrupoAcao(grupoAcao)
            setDataComposicaoFamiliar(data.composicao_familiar)
            setDadoCarregado(true)
          })

      } catch (error) {
        setLoading(false)
        setCarregandoPagina(true)
        if (error.code) {
          switch (error.code) {
            case 0:
              setShowAlertErroServidor(true)
              setLoading(false)
              setTimeout(() => {
                setShowAlertErroServidor(false)
              }, 5000)
              break
            case 403:
              SignOutForce()
              window.location.reload()
              break
            case 409:
              setMessageError(error.message)
              setShowAlertError(true)
              setLoading(false)
              setTimeout(() => {
                setShowAlertError(false)
                setLoading(false)
              }, 3000)
              break
            default:
              setMessageError(error.message)
              setShowAlertError(true)
              setTimeout(() => {
                setShowAlertError(false)
                setLoading(false)
              }, 3000)
              break
          }
        } else {
          setMessageError(error.message)
          setShowAlertError(true)
          setLoading(false)
        }
      }
    }

    if (carregandoPagina) {
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleErrors(error) {
    if (error.code) {
      switch (error.code) {
        case 0:
          setShowAlertErroServidor(true)
          setTimeout(() => {
            setLoading(false)
            setShowAlertErroServidor(false)
          }, 5000)
          break
        case 403:
          SignOutForce()
          window.location.reload()
          break
        case 409:
          setMessageError(error.message)
          setShowAlertError(true)
          setLoading(false)
          setTimeout(() => {
            setShowAlertError(false)
            setLoading(false)
          }, 3000)
          break
        default:
          setMessageError(error.message)
          setShowAlertError(true)
          setTimeout(() => {
            setShowAlertError(false)
            setLoading(false)
          }, 3000)
          break
      }
    } else {
      setMessageError(error.message)
      setShowAlertError(true)
      setTimeout(() => {
        setLoading(false)
        setShowAlertError(false)
      }, 4000)
    }
  }

  const onSubmit = (data) => {

    async function Registrar() {
      try {
        data = RegisterSanitize.SanitizeData(data)

        ClienteService
          .register(data)
          .then(() => {

            setModalAviso(true)
            setLoading(false)
            setTimeout(() => {
              setModalAviso(false)
              window.location.reload();
            }, 2000)
            reset()
          })
          .catch((error) => {
            handleErrors(error)
          })
      } catch (error) {
        setLoading(false)
      }
    }

    async function Editar() {
      try {
        data = RegisterSanitize.SanitizeData(data)

        ClienteService
          .update(clienteId, data)
          .then(() => {

            function validaFotos() {
              if (files.length === 0 && allDeletedFiles === files) return

              if (allDeletedFiles.length > 0) {
                allDeletedFiles.forEach((file) => {
                  cliente.deleteFoto(clienteId, file.id)
                    .catch(error => {
                      console.error(error);
                      handleErrors(error);
                    });
                })
              }

              if (files.length > 0) {
                const formData = new FormData();

                files.forEach((file) => {
                  formData.append(String(file.name), file);
                });
                cliente.registerFoto(clienteId, formData)
                  .then(response => {

                  })
                  .catch(error => {
                    handleErrors(error);
                  });
              }
            }

            validaFotos()
            setModalAviso(true)

            setTimeout(() => {
              setModalAviso(false)
              window.history.back()
            }, 2000)
          })
          .catch((error) => {
            handleErrors(error)
          })
      } catch (error) {
        setLoading(false)
      }
    }

    setLoading(true)
    if (!clienteId) {
      Registrar()
    } else {
      Editar()
    }
  }

  return (
    <>
      <Container className='col-lg-8 col-md-12 col-sm-12  mt-5' fluid>
        <ModalAviso show={modalAviso} message={strModalMensagem} color='bg-success' backdrop={false} />
        <ModalAviso show={showAlertErroServidor} message='Verifique sua conexão com a internet.' color='bg-danger' backdrop={false} />
        <ModalAviso show={alertError} message={messageError} color='bg-warning' backdrop={false} />
        <ModalAviso show={carregandoPagina} message='Carregando registro...' color='bg-light' backdrop={true} />

        <div className='row'>
          <div className='col mb-3'>
            <h3>Cliente</h3>
          </div>
          <div className='col-auto'>
            <Button variant='outline-secondary' onClick={() => window.history.back()}><FiArrowLeft /> Voltar</Button>
          </div>
        </div>

        <form style={{ paddingBottom: 55 }} onSubmit={handleSubmit(onSubmit)} >
          <ClienteCadastroCliente
            register={register}
            errors={errors}
            setValue={setValue}
            status={status}
            clienteId={clienteId}
          />
          <ClienteCadastroEndereco
            register={register}
            errors={errors}
            setValue={setValue}
            carregandoPagina={carregandoPagina}
            setCarregandoPagina={setCarregandoPagina}
            alertError={alertError}
            setShowAlertError={setShowAlertError}
            message={messageError}
          />
          <ClienteCadastroEscolaridade register={register} />
          <ClienteCadastroEmprego register={register} setValue={setValue} />
          <ClienteCadastroBeneficio register={register} setValue={setValue} />
          <ClienteCadastroMoradia register={register} setValue={setValue} />

          <ClienteCadastroFotos
            getValues={getValues}
            files={files}
            setFiles={setFiles}
            allDeletedFiles={allDeletedFiles}
            setAllDeletedFiles={setAllDeletedFiles}
          />

          <ClienteCadastroHistoricoFamiliar register={register} setValue={setValue} />
          <ClienteCadastroVeiculo register={register} setValue={setValue} />
          <ClienteCadastroGrupoAcao
            register={register}
            setValue={setValue}
            db={dataGrupoAcao}
            setDB={setDataGrupoAcao}
            dadoCarregado={dadoCarregado}
            dadoGrupoAcao={dadoGrupoAcao}
          />
          <ClienteCadastroComposicaoFamiliar
            register={register}
            setValue={setValue}
            dadosFamiliar={dataComposicaoFamiliar}
            setDadosFamiliar={setDataComposicaoFamiliar}
            clienteId={clienteId}
          />
          {
            (clienteId) ?
              <>
                <ClienteCadastroResponsavel register={register} setValue={setValue} />
                <ClienteCadastroUltimaAtualizacao register={register} setValue={setValue} />
              </>
              : ''
          }

          <Navbar className='d-flex justify-content-end' fixed="bottom" style={{ backgroundColor: '#e5e5e5' }} >
            <div style={{ backgroundColor: '#e5e5e5', width: '100%', display: 'flex', justifyContent: 'flex-end', marginRight: '17.3%' }}>
              {(['ATIVADO', 'CANCELADO', 'BLOQUEADO'].indexOf(getValues('status')) > -1)
                ?
                <Button variant="success" type="submit" disabled={loading}>
                  {
                    loading ? <><Spinner animation='border' size='sm' /> Aguarde...</> : <><FiCheck /> Salvar</>
                  }
                </Button>
                : ''
              }
            </div>
          </Navbar>
        </form>
      </Container>
    </>
  )
}

export { ClienteCadastro }
