import { Button, Modal } from 'react-bootstrap'
import React from 'react'

function ModalConfirmacao(props) {
  return (
    <>
      <Modal show={props.show} onHide={props.onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Acompanhamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>Confirmar o recebimento do benefício?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={props.onHide} disabled={props.loading}>
            Cancelar
          </Button>
          <Button variant="success" onClick={props.handleSubmit} disabled={props.loading}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalConfirmacao
