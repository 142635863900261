import React, { useMemo, useState, useEffect } from 'react'
import { Button, Container, FormControl, InputGroup, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import { SiWhatsapp } from 'react-icons/si'
import { BsPrinter } from 'react-icons/bs'
import { Cards } from '../../components/cards'
import { ModalAviso } from '../../components/modalAviso'
import { useForm } from 'react-hook-form'

import TableWithSubcomponent from '../../components/tableWithSubcomponent'
import ClienteService from '../../services/cliente'

import '../../index.css'
import { Link } from 'react-router-dom'
import { FiEdit2, FiSearch } from 'react-icons/fi'

function Aniversariantes() {

  const { register, setValue, getValues } = useForm()

  const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  const [data, setData] = useState([])
  const [month, setMonth] = useState(new Date().getMonth())
  const [loading, setLoading] = useState(true)
  const [alertError, setShowAlertError] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [newData, setNewData] = useState([])
  const [render, setRender] = useState(false)

  useEffect(() => {
    setLoading(true)
    let selectedMonth = Number(month) + 1
    ClienteService.listAllAniversariantes(selectedMonth, 'ativado')
      .then(dados => {
        setData(dados)
        setNewData(dados)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        setShowAlertError(true)
        setMessageError(error)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (render === true) {
      setRender(false)
      setLoading(true)

      let selectedMonth = Number(month) + 1
      let status = getValues('status')
      let idade = Number(getValues('idade'))
      if (idade === 0) idade = 100
      let filteredData

      ClienteService.listAllAniversariantes(selectedMonth, status !== 'todos' ? status : '')
        .then(dados => {
          if (idade !== '') {
            filteredData = dados === undefined ? '' : dados.map((item) => {
              const birthday = item.data_nascimento;
              const birthDate = new Date(birthday);
              const currentDate = new Date();
              const diffInMs = currentDate.getTime() - birthDate.getTime();
              const ageInMs = new Date(diffInMs);
              const ageInYears = Math.abs(ageInMs.getUTCFullYear() - 1970);
              if (Number(ageInYears) <= idade) return item;
            })
              .filter((item) => item !== undefined);
          }
          setData(dados)
          setNewData(filteredData)
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
          setShowAlertError(true)
          setMessageError(error)
        })
    }
  }, [render]);

  function formatarData(data) {
    if (data === undefined) return
    const partes = data.split('-'); // Divide a string em ano, mês e dia
    if (partes.length !== 3) {
      return 'Data inválida'; // Verifica se a data está no formato esperado
    }
    const [ano, mes, dia] = partes;
    return `${dia}/${mes}/${ano}`;
  }

  const columns = useMemo(
    () => [
      {
        id: 'nome',
        Header: () => (
          <div style={{ minWidth: '300px' }} className='texto-imprimir'>Nome</div>
        ),
        accessor: 'nome',
        Cell: ({ value }) => {
          return <div style={{ minWidth: '300px' }} className='texto-imprimir'>{value}</div>
        }
      },

      {
        id: 'idade',
        Header: () => (
          <div style={{ width: '50px' }} className='d-flex justify-content-center texto-imprimir'>Idade</div>
        ),
        accessor: dados => {
          const birthday = dados.data_nascimento;
          const birthDate = new Date(birthday);
          const currentDate = new Date();
          const diffInMs = currentDate.getTime() - birthDate.getTime();
          const ageInMs = new Date(diffInMs);
          const ageInYears = Math.abs(ageInMs.getUTCFullYear() - 1970);
          return ageInYears
        },
        Cell: ({ value }) => {
          return <div style={{ width: '50px' }} className='d-flex justify-content-center texto-imprimir'>{value}</div>
        }
      },
      {
        id: 'data_nascimento',
        Header: () => (
          <div style={{ width: '137px' }} className='d-flex justify-content-center texto-imprimir'>Data nascimento</div>
        ),
        accessor: dados => {
          const formattedDate = formatarData(dados.data_nascimento)
          return formattedDate
        },
        Cell: ({ value }) => {
          return <div style={{ width: '137px' }} className='d-flex justify-content-center texto-imprimir'>{value}</div>
        }
      },
      {
        id: 'acoes',
        Header: () => (
          <div style={{ textAlign: 'center' }} className='d-print-none'>Ações</div>
        ),
        accessor: 'telefone',
        Cell: (tableProps) => (
          <div className='align-center text-center d-print-none'>
            {
              tableProps.value
                ? (
                  <>
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip id='tooltip-bottom'>
                          Abrir whatsapp
                        </Tooltip>
                      }
                    >
                      <a className='btn btn-sm btn-outline-success' target='_blank' rel='noreferrer' href={`https://web.whatsapp.com/send?phone=+55${tableProps.value}`}>
                        <SiWhatsapp />
                      </a>

                    </OverlayTrigger>
                  </>
                )
                : (<></>)
            }&nbsp;

            <>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip id='tooltip-bottom'>
                    Editar
                  </Tooltip>
                }
              >

                <Link
                  className='btn btn-sm btn-outline-success'
                  to={`/cliente/cadastro/${tableProps.row.original.id}`}
                >
                  <FiEdit2 />
                </Link>

              </OverlayTrigger>
            </>
            &nbsp;
          </div>
        )
      }
    ],
    []
  );

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setRender(true)
    }
  };

  return (
    <div>
      <ModalAviso show={alertError} message={messageError} color='bg-warning' backdrop={false} />
      <Container className='col-xl-9 col-lg-11 col-md-12 col-sm-12 mt-5' fluid>
        <Cards>
          <div className='d-flex justify-content-between'>
            <h3>Aniversariantes
              <span className='d-none d-print-inline ms-2'>{months[month]}</span>
            </h3>
            <Button className='d-print-none' onClick={() => window.print()} variant='outline-secondary'><BsPrinter /></Button>
          </div>
        </Cards>
        <Cards className='d-print-none'>
          <div className='col-md-auto col-sm-12 d-flex justify-content-center flex-wrap'>
            {months.map((item, i) =>
              <Button
                onClick={() => { setMonth(i); setRender(true) }}
                variant={month === i ? 'success ms-2 mt-1' : 'outline-secondary ms-2 mt-1'}
              > {item} </Button>
            )}
          </div>

        </Cards>
        <Cards>

          <div className='d-flex align-items-center justify-content-between mb-3 d-print-none'>
            <div className='col-3 d-flex align-items-center'>
              <p style={{ whiteSpace: 'nowrap' }}>Status:</p>
              <InputGroup className='ps-3 text-right'>
                <Form.Select
                  {...register('status')}
                  onChange={e => { setValue('status', e.target.value); setRender(true) }}
                >
                  <option value="todos">Todos</option>
                  <option value="ativado" selected>Ativo</option>
                  <option value="bloqueado">Bloqueado</option>
                  <option value="cancelado">Cancelado</option>
                </Form.Select>
              </InputGroup>
            </div>
            <div className='col-3 d-flex align-items-center'>
              <p style={{ whiteSpace: 'nowrap' }}>Filtrar idade:</p>
              <InputGroup className='ps-3 text-right'>
                <FormControl
                  id='txtFiltro'
                  type='text'
                  {...register('idade')}
                  onBlur={e => setRender(true)}
                  onKeyPress={handleKeyPress}
                  autoFocus
                />
                <InputGroup.Text htmlFor='txtFiltro'><FiSearch /></InputGroup.Text>
              </InputGroup>
            </div>
          </div>

          <TableWithSubcomponent
            columns={columns}
            data={newData === undefined || newData === null || newData === '' ? [] : newData}
            loading={loading}
            aniversario={true}
          />
        </Cards>
      </Container>
    </div>
  )
}

export { Aniversariantes }
