import ConfigURL from '../config/api'
import security from './security'

const grupoAcao = {}

grupoAcao.register = body => {
  return security.validation(ConfigURL.axios.post(ConfigURL.api.grupoAcao.post.register, body))
}

grupoAcao.listAll = (filter) => {
  if (filter) {
    return security.validation(ConfigURL.axios.get(ConfigURL.api.grupoAcao.get.listAll + '?sort=nome&fields=' + filter))
  } else {
    return security.validation(ConfigURL.axios.get(ConfigURL.api.grupoAcao.get.listAll + '?sort=nome'))
  }
}

grupoAcao.listAllActive = (fields) => {
  if (fields) {
    return security.validation(ConfigURL.axios.get(`${ConfigURL.api.grupoAcao.get.listAll}?sort=nome&fields=${fields}`))
  } else {
    return security.validation(ConfigURL.axios.get(`${ConfigURL.api.grupoAcao.get.listAll}?sort=nome`))
  }
}

grupoAcao.list = (id) => {
  return security.validation(ConfigURL.axios.get(`${ConfigURL.api.grupoAcao.get.list}/${id}`))
}

grupoAcao.filterByStatus = body => {
  return security.validation(ConfigURL.axios.get(`${ConfigURL.api.grupoAcao.get.listAll}?sort=nome&status=${body.status}&fields=${body.fields}`))
}

grupoAcao.update = (id, body) => {
  return security.validation(ConfigURL.axios.put(`${ConfigURL.api.grupoAcao.put.update}/${id}`, body))
}

export default grupoAcao