import React from 'react'
import { FiArrowDown, FiArrowUp, FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { useTable, usePagination, useGlobalFilter, useSortBy, useExpanded } from 'react-table'
import { Spinner } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';

import { GlobalFilter } from '../components/globalFilter'
import './styles.css'

function TableWithSubcomponent({ columns, data, loading, aniversario }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      loading,
      initialState: { pageIndex: 0, pageSize: aniversario === true ? 1000 : 10 },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  )

  const { globalFilter } = state;

  return (
    <>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      <div className='table-responsive'>
        <Table {...getTableProps()} hover>
          <thead className='tb-head d-print-none'>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? <FiArrowDown /> : <FiArrowUp />) : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <div className='tb-head tb-print-header' style={{display: 'none'}}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? <FiArrowDown /> : <FiArrowUp />) : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </div>
          <tbody {...getTableBodyProps()}>
            {
              loading
                ? (
                  <>
                    <tr>
                      <td colSpan='10000'></td>
                    </tr>
                    <tr>
                      <td className='text-center' colSpan='10000'>
                        <Spinner animation='border' variant='dark' size='sm' /> Carregando informações...
                      </td>
                    </tr>
                  </>
                )
                : (
                  page.map((row, idx) => {
                    prepareRow(row)
                    return (
                      <tr>
                        {row.cells.map(cell => {
                          return (
                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          )
                        })}
                      </tr>
                    )
                  }))
            }
          </tbody>
        </Table>
      </div>
      <div className='row'>
        <div className='col mt-1 '>
          <h6 className='texto-imprimir'>{`Total registros: ${data.length}`}</h6>
        </div>
        {
          aniversario !== true &&

        <div className='col-auto' style={{ padding: '0.5rem' }}>
          <button className='btn btn-sm btn-light' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <FiChevronsLeft />
          </button>{' '}
          <button className='btn btn-sm btn-light' onClick={() => previousPage()} disabled={!canPreviousPage}>
            <FiChevronLeft />
          </button>{' '}
          <button className='btn btn-sm btn-light' onClick={() => nextPage()} disabled={!canNextPage}>
            <FiChevronRight />
          </button>{' '}
          <button className='btn btn-sm btn-light' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <FiChevronsRight />
          </button>{' '}
          <span className='col-md-2'>
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>
          </span>&nbsp;&nbsp;&nbsp;
          <select
            className='btn btn-sm btn-secondary dropdown-toggle '
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50, 100, 500, 1000].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </div>
                }
      </div>
    </>
  )
}

export default TableWithSubcomponent
