import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FiFilter, FiMinus, FiPlus, FiSearch, FiTrash2 } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { ModalAviso } from '../../components/modalAviso'

import FormSanitize from '../../libs/form.sanitize'
import FormValidate from '../../libs/form.validate'
import ClienteService from '../../services/cliente'
import GrupoAcaoService from '../../services/grupoAcao'

import { ModalAcompanhamentoGrupoAcaoFiltro } from './modalAcompanhamentoGrupoAcaoFiltro'

function ModalFiltroAcompanhamento(props) {
  const [modalGrupoAcao, setModalGrupoAcao] = useState(false)
  const [grupoFiltro, setGrupoFiltro] = useState([])
  const [dadosGrupoTabela, setDadosGrupoTabela] = useState([])
  const [dbGrupo, setDbGrupo] = useState([])
  const [grupoCarregado, setGrupoCarregado] = useState(false)
  const [modalAvisoErroServidor, setModalAvisoErroServidor] = useState(false)
  const [modalAvisoAlerta, setModalAvisoAlerta] = useState(false)
  const [messageError, setMessageError] = useState('')

  const history = useHistory()

  const redirectUrl = (event) => {

    const objDataInicial = props.getValues('data_inicial')
    let objDataFinal = props.getValues('data_final')
    let objTipoData = props.getValues('tipo_data')
    const objPessoa = FormSanitize.Sanitize(props.getValues('pessoa.cpf'))
    const objPessoaNome = props.getValues('pessoa.nome')
    const objGrupoAcao = props.getValues('grupo_acao.id')
    const objGrupoAcaoNome = props.getValues('grupo_acao.nome')

    if (
      objDataInicial
      && !objDataFinal
    ) {
      objDataFinal = FormSanitize.DateHourNow().dateNow
    }

    if (!objDataInicial
      && !objDataFinal
    ) {
      objTipoData = ''
    }

    let filtro = '&';
    if (objDataInicial) filtro = filtro + `data_inicial=${objDataInicial}&`
    if (objDataFinal) filtro = filtro + `data_final=${objDataFinal}&`
    if (objPessoa) filtro = filtro + `pessoa_cpf=${objPessoa}&`
    if (objTipoData) filtro = filtro + `tipo_data=${objTipoData}&`
    if (objPessoaNome) filtro = filtro + `cliente=${objPessoaNome}&`
    if (objGrupoAcao) filtro = filtro + `grupo_acao=${objGrupoAcao}&`
    if (objGrupoAcaoNome) filtro = filtro + `grupo=${objGrupoAcaoNome}&`

    history.push('/acompanhamento/beneficio?' + filtro)
    props.onHide()
  }

  function handleErrors(error) {
    if (error.code) {
      switch (error.code) {
        case 0:
          setModalAvisoErroServidor(true)
          setTimeout(() => {
            setModalAvisoErroServidor(false)
          }, 5000)
          break;
        case 403:
          window.location.reload()
          break;
        case 409:
          setMessageError(error.message)
          setModalAvisoAlerta(true)
          setTimeout(() => {
            setModalAvisoAlerta(false)
          }, 3000)
          break;
        default:
          setMessageError(error.message)
          setModalAvisoAlerta(true)
          setTimeout(() => {
            setModalAvisoAlerta(false)
          }, 3000)
      }
    } else {
      setMessageError(error.message)
      setModalAvisoAlerta(true)
      setTimeout(() => {
        setModalAvisoAlerta(false)
      }, 4000)
    }
  }

  const handlePessoa = () => {
    ClienteService
      .listCPF(FormSanitize.Sanitize(props.getValues('pessoa.cpf')))
      .then(dados => {
        const data = dados.data

        if (data.length > 0) {
          props.setValue('pessoa.nome', data[0].nome)
        } else {

          setMessageError('Pessoa não encontrada!')
          setModalAvisoAlerta(true)
          setTimeout(() => {
            setModalAvisoAlerta(false)
          }, 2000)
        }
      })
      .catch(error => {
        handleErrors(error)
      })
  }

  const handleGrupoAcao = () => {
    setModalGrupoAcao(true)

    GrupoAcaoService
      .listAll('id, nome')
      .then(dados => {
        setDbGrupo(dados.data)
        setGrupoCarregado(true)
      })
      .catch(error => {
        handleErrors(error)
      })
  }

  const handleGrupoAcaoCancelar = () => {
    setGrupoFiltro([]);
    props.setValue('grupo_acao.nome', '')
  }

  return (
    <>
      <ModalAviso show={modalAvisoAlerta} message={messageError} color='bg-warning' backdrop={false} />
      <ModalAviso show={modalAvisoErroServidor} message='Verifique sua conexão com a internet.' color='bg-danger' backdrop={false} />
      <ModalAcompanhamentoGrupoAcaoFiltro
        show={modalGrupoAcao}
        onHide={() => { setModalGrupoAcao(false) }}
        setGrupoNovo={setGrupoFiltro}
        grupoNovo={grupoFiltro}
        dbGrupo={dbGrupo}
        dadosGrupoTabela={dadosGrupoTabela}
        setDadosGrupoTabela={setDadosGrupoTabela}
        grupoCarregado={grupoCarregado}
        setModalGrupoAcao={setModalGrupoAcao}
        setValue={props.setValue}
      />
      <Modal {...props} size='lg' centered>
        <Modal.Header closeButton>
          <Modal.Title>Filtrar acompanhamentos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={props.onSubmit}>
            <div className='row'>
              <div className='col-sm-12 col-md-4 mb-3'>
                <div className='form-grop'>
                  <label htmlFor='data_inicial'>Data inicial</label>
                  <input className='form-control' id='data_inicial' type='date' {...props.register('data_inicial')} />
                </div>
              </div>
              <div className='col-sm-12 col-md-4 mb-3'>
                <div className='form-group'>
                  <label htmlFor='data_final'>Data final</label>
                  <input className='form-control' id='data_final' type='date' {...props.register('data_final')} />
                </div>
              </div>
              <div className='col-sm-12 col-md-4'>
                <div className='form-group'>
                  <label htmlFor='tipo_data'>Tipo de data</label>
                  <select className='form-select' id='tipo_data' {...props.register('tipo_data')} >
                    <option value='cadastrado' selected>Data de cadastro</option>
                    <option value='finalizado'>Data de finalização</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col mb-3'>
                <label htmlFor='grupo_acao'>Grupo de ação</label>
                <div className='input-group'>
                  <input className='form-control' id='grupo_acao' type='text' {...props.register('grupo_acao.nome')} readOnly />
                  <button
                    className='btn btn-outline-success'
                    id="btnFiltroGrupo"
                    type='button'
                    title='Adicionar grupo de ação'
                    onClick={(e) => { handleGrupoAcao() }}
                  >
                    <FiPlus />
                  </button>
                  <button
                    className='btn btn-outline-danger'
                    id='btnFiltroGrupoLimpa'
                    type='button'
                    title='Limpar filtro'
                    onClick={() => { handleGrupoAcaoCancelar() }}
                  >
                    <FiMinus />
                  </button>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 col-md-4 mb-3'>
                <div className='form-group'>
                  <label htmlFor='cliente'>Cliente</label>
                  <div className='input-group'>
                    <input
                      className='form-control'
                      id="txtPessoaCpf"
                      name='cpf'
                      type='text'
                      autoComplete='off'
                      onKeyPress={(e) => {
                        if (e.charCode === 13) {
                          e.preventDefault()
                          e.stopPropagation()
                          handlePessoa(e.target.value)
                        }
                      }}
                      {...props.register('pessoa.cpf', {
                        ...FormValidate.Require,
                        ...FormValidate.MinLength(11),
                        ...FormValidate.MaxLength(14),
                        ...FormValidate.CPF,
                      })}
                      onChange={(e) => props.setValue(e.target.name, FormValidate.onChange.CPF(e.target.value))}
                    />

                    <button
                      className='btn btn-outline-secondary'
                      id="btnFiltroCliente"
                      type='button'
                      onClick={() => { handlePessoa() }}
                      title='Pesquisar CPF'
                    >
                      <FiSearch />
                    </button>

                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-8 mb-3'>
                <label htmlFor='pessoa.nome'>Cliente</label>
                <input className='form-control' id='pessoa.nome' type='text' readOnly {...props.register('pessoa.nome')} />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-secondary' onClick={props.cleanFilter}>
            <FiTrash2 /> Limpar filtro
          </Button>
          <Button variant='primary' onClick={() => { redirectUrl() }}>
            <FiFilter /> Filtrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export { ModalFiltroAcompanhamento }