const security = {}

security.validation = api => {
  return new Promise((resolve, reject) => {
    api
      .then(dados => {
        const { data } = dados

        if (!data) return reject({ code: 1, message: 'Nenhuma informação recebida!' })

        return resolve(data)
      })
      .catch(error => {
        if (!error.response) return reject({ code: 0, message: 'Sem resposta do servidor! Tente novamente em alguns segundos' })
        return reject({
          code: error.response.status,
          message: error.response.data?.messageDetail
            ? error.response.data?.messageDetail
            : error.response.data?.causes?.length > 0 ? error.response.data?.causes[0] : error.response.data?.message
        })
      })
  })
}

export default security