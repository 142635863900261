import { useDropzone } from 'react-dropzone';
import { useEffect, useState } from 'react';
import './MyDropzone.css';
import { Button, Modal } from 'react-bootstrap';

function MyDropzone({ fotos, files, setFiles, allDeletedFiles, setAllDeletedFiles, urlBase }) {
  const [previousFiles, setPreviousFiles] = useState(fotos ? fotos : [])
  const [editarFotos, setEditarFotos] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setPreviousFiles(fotos ? fotos : [])
  }, [fotos]);


  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.png']
    },
    onDrop: (acceptedFiles) => {
      setFiles(prevFiles => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      ]);
    }

  });

  const thumbs =

    files.map((file, index) => (
      <div key={file.index}>
        <div className="thumb">
          <div className="thumb-inner">
            <img
              src={file.preview}
              alt={file.name}
              onClick={() => handleImageClick(file)}
            />
            {editarFotos &&
              <button
                className="thumb-delete"
                type='button'
                onClick={() => handleRemoveFile(index)}
              >
                Excluir
              </button>
            }
          </div>
        </div>
      </div>
    ));

  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    if ((newFiles?.length === 0) && (previousFiles?.length === 0)) setEditarFotos(false)
  };

  const handleRemovePreviousFile = (index, id) => {
    const newFiles1 = [...previousFiles];
    const deletedFile = previousFiles.filter((item) => { if (item.id === id) return item; else return false })

    newFiles1.splice(index, 1);
    setAllDeletedFiles([...allDeletedFiles, deletedFile[0]])
    setPreviousFiles(newFiles1);
    if ((newFiles1?.length === 0)) setEditarFotos(false)
  };

  const handleImageClick = (file) => {
    setSelectedFile(file);
    setModalOpen(true);
  };

  return (
    <>

      <Modal show={modalOpen} onHide={() => setModalOpen(false)} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>{selectedFile?.name ? selectedFile?.name : selectedFile?.filename}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-center align-items-center'>

          <img src={selectedFile?.preview ? selectedFile?.preview : urlBase + selectedFile?.url}
            alt={selectedFile?.name ? selectedFile?.name : selectedFile?.filename} style={{ maxWidth: '90vw', maxHeight: '80vh' }} />

        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalOpen(false)}>Fechar</Button>
        </Modal.Footer>
      </Modal>


      <section className='d-flex '>
        <div>
          <button {...getRootProps()} className="dropzone me-3" style={{ backgroundColor: editarFotos ? '#aeaeae' : '' }} type='button' disabled={editarFotos}>
            <input {...getInputProps()} />
            <h1 className='text-success'>+</h1>
            <h5>Adicionar</h5>
          </button>
          {
            ((previousFiles?.length > 0) || (files?.length > 0)) &&
            <button className="dropzone me-3" onClick={() => setEditarFotos(!editarFotos)} type='button'>
              <h1 className='text-danger'>-</h1>
              <h5>{editarFotos ? 'Concluído' : 'Remover'}</h5>
            </button>

          }
        </div>

        <div className='d-flex flex-wrap'>

          {previousFiles?.map((file, index) => (
            <div key={file?.id}>
              <div className="thumb">
                <div className="thumb-inner">
                  <img
                    src={urlBase + file?.url}
                    alt={file?.filename}
                    onClick={() => handleImageClick(file)}
                  />
                  {editarFotos &&
                    <button
                      className="thumb-delete"
                      type='button'
                      onClick={() => handleRemovePreviousFile(index, file.id)}
                    >
                      Excluir
                    </button>
                  }
                </div>
              </div>
            </div>
          ))
          }

          {thumbs}

        </div>
      </section>
    </>
  );
}

export default MyDropzone;
