import { axios, URL } from './axios'

const config = {}

config.axios = axios

config.url = URL

config.api = {}

config.api.cliente = {
  get: {
    listAll: '/pessoa',
    listAllAniversariantes: '/pessoa/aniversario',
    list: '/pessoa'
  },
  post: {
    register: '/pessoa',
  },
  put: {
    update: '/pessoa'
  }
}

config.api.grupoAcao = {
  get: {
    listAll: '/grupo_acao',
    list: '/grupo_acao'
  },
  post: {
    register: '/grupo_acao'
  },
  put: {
    update: '/grupo_acao'
  }
}

config.api.usuario = {
  get: {
    listAll: '/usuario',
    list: '/usuario',
    relatorioSimples: '/usuario/relatorio/simples'
  },
  post: {
    register: '/usuario'
  },
  put: {
    update: '/usuario'
  }
}

config.api.acompanhamentoBeneficio = {
  get: {
    listAll: '/acompanhamento_beneficio',
    list: '/acompanhamento_beneficio'
  },
  post: {
    register: '/acompanhamento_beneficio'
  },
  put: {
    update: '/acompanhamento_beneficio'
  }
}

config.api.auth = {
  get: {
    me: '/auth/me'
  },
  post: {
    login: '/auth/login',
    logout: '/auth/logout',
    forgot: '/auth/recuperarsenha',
    forgotReset: '/auth/recuperarsenha/confirmar',
  },
  put: {
    updatePassword: '/auth/alterarsenha',
    updatePasswordForce: '/auth/alterarsenha/forcado'
  },
  delete: {}
}

export default config;