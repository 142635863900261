import React from 'react'

import { Cards } from '../../components/cards';
import FormValidate from '../../libs/form.validate';

function ClienteCadastroEmprego({ register, setValue }) {

  return (
    <Cards>
      <div className='row'>
        <div className='col'>
          <h5>Emprego</h5>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-3 col-sm-12 mb-3'>
          <label htmlFor='emprego_possui'>Possui</label>
          <select
            className='form-select'
            {...register('emprego_possui')}
          >
            <option value={false}>Não</option>
            <option value={true}>Sim</option>
          </select>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-3 col-sm-12 mb-3'>
          <label htmlFor='emprego_renda'>R$ Renda</label>
          <input
            className='form-control'
            type='text'
            {...register('emprego_renda')}
            onKeyUp={(e) => { setValue(e.target.name, FormValidate.onChange.Moeda(e.target?.value)) }}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col col-sm-12 mb-3'>
          <label htmlFor='emprego_cargo'>Cargo ou Função</label>
          <input className='form-control' type='text' {...register('emprego_cargo')} />
        </div>
      </div>

      <div className='row'>
        <div className='col col-sm-12 mb-3'>
          <label htmlFor='emprego_empresa'>Empresa</label>
          <input className='form-control' type='text' {...register('emprego_empresa')} />
        </div>
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <label htmlFor='emprego_observacao'>Observação</label>
          <textarea className='form-control' rows='5' {...register('emprego_observacao')} />
        </div>
      </div>
    </Cards>
  )
}

export { ClienteCadastroEmprego }
