import React from 'react'
import { Cards } from '../../components/cards'
import MyDropzone from '../../components/dropZone/dropZone.js'
import config from '../../config/api'

function ClienteCadastroFotos({ getValues, files, setFiles, allDeletedFiles, setAllDeletedFiles }) {

  const fotos = getValues('fotos')

  return (
    <Cards>
      <div className='row'>
        <div className='col'>
          <h5>Fotos</h5>
        </div>
      </div>

      <div className='row'>
        <MyDropzone
          fotos={fotos}
          files={files}
          setFiles={setFiles}
          allDeletedFiles={allDeletedFiles}
          setAllDeletedFiles={setAllDeletedFiles}
          urlBase={config.url} />
      </div>
    </Cards>
  )
}

export { ClienteCadastroFotos }
