import React, { useEffect, useState } from 'react'
import { Button, Container, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form'
import { FiArrowLeft, FiCheck } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';

import { CampoObrigatorio } from '../../components/campoObrigatorio'
import UsuarioService from '../../services/usuario'
import { Cards } from '../../components/cards';
import { setValueForm } from '../../libs/setValueForm';
import { useAuth } from '../../services/auth';
import { ModalAviso } from '../../components/modalAviso';
import UsuarioAlterarSenha from './usuarioAlterarSenha';

function UsuarioCadastro() {
  const { register, formState: { errors }, reset, setValue, getValues, handleSubmit } = useForm()
  const { SignOutForce } = useAuth()
  const [showEditar, setShowEditar] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalAviso, setModalAviso] = useState(false)
  const [showAlertErroServidor, setShowAlertErroServidor] = useState(false)
  const [alertError, setShowAlertError] = useState(false)
  const [carregandoPagina, setCarregandoPagina] = useState(true)
  const [messageError, setMessageError] = useState('')
  const { usuarioId } = useParams()
  const history = useHistory()
  let strModalMensagem = 'Registro salvo com sucesso!'

  useEffect(() => {
    if (!usuarioId) {
      setCarregandoPagina(false)
      return;
    }

    async function loadData() {
      try {
        setShowEditar(true)
        await UsuarioService
          .list(usuarioId)
          .then((data) => {
            setValueForm(setValue, data)
            setCarregandoPagina(false)
          })
      } catch (error) {
        setLoading(false)
        setCarregandoPagina(true)
      }
    }

    if (carregandoPagina) {
      loadData()
    }
  }, [usuarioId, setValue, carregandoPagina])

  function handleErrors(error) {
    if (error.code) {
      switch (error.code) {
        case 0:
          setShowAlertErroServidor(true)
          setLoading(false)
          break
        case 403:
          SignOutForce()
          window.location.reload()
          break
        case 409:
          setMessageError(error.message)
          setShowAlertError(true)
          setLoading(false)
          setTimeout(() => {
            setShowAlertError(false)
            setLoading(false)
          }, 3000)
          break
        default:
          setMessageError(error.message)
          setShowAlertError(true)
          setTimeout(() => {
            setShowAlertError(false)
            setLoading(false)
          }, 3000)
          break
      }
    } else {
      setMessageError(error.message)
      setShowAlertError(true)
      setTimeout(() => {
        setShowAlertError(false)
        setLoading(false)
      }, 3000)
    }
  }

  const onSubmit = (data, e) => {
    e.preventDefault()
    const values = getValues()

    if (values.nome === '') {
      setMessageError('Informe o nome do usuário')
      setShowAlertError(true)
      setTimeout(() => {
        setShowAlertError(false)
        setLoading(false)
      }, 3000)
      return
    }
    if (values.email === '') {
      setMessageError('Informe o email do usuário')
      setShowAlertError(true)
      setTimeout(() => {
        setShowAlertError(false)
        setLoading(false)
      }, 3000)
      return
    }

    async function Registrar() {
      try {
        UsuarioService
          .register({
            nome: data.nome,
            email: data.email,
            senha: data.senha,
            tipo_usuario: data.tipo_usuario
          })
          .then(() => {
            setModalAviso(true)
            setTimeout(() => {
              setLoading(false)
              setModalAviso(false)
            }, 2000)
            reset()
          }).catch((error) => {
            handleErrors(error)
          })
      } catch (error) {
        setLoading(false)
      }
    }

    async function Editar() {
      try {
        UsuarioService
          .update(
            usuarioId, {
            nome: data.nome,
            email: data.email,
            status: data.status,
            senha: data.senha,
            tipo_usuario: data.tipo_usuario
          })
          .then(() => {
            setModalAviso(true)
            setTimeout(() => {
              setLoading(false)
              setModalAviso(false)
              history.push('/usuario')
            }, 2000);
          })
          .catch((error) => {
            handleErrors(error)
          })
      } catch (error) {
        setLoading(false)
      }
    }

    setLoading(true)
    if (!usuarioId) {
      Registrar()
    } else {
      Editar()
    }
  }

  return (
    <>
      <Container className='col-md-12 col-sm-12 w-75 h-75 container-form mt-5'>
        <section>
          <ModalAviso show={modalAviso} message={strModalMensagem} color='bg-success' backdrop={false} />
          <ModalAviso show={showAlertErroServidor} message='Verifique sua conexão com a internet.' color='bg-danger' backdrop={false} />
          <ModalAviso show={alertError} message={messageError} color='bg-warning' backdrop={false} />
          <ModalAviso show={carregandoPagina} message='Carregando registro...' color='bg-light' backdrop={true} />

          <div className='row'>
            <div className='col mb-3'>
              <h3>Usuário</h3>
            </div>
            <div className='col-auto'>
              <Button variant='outline-secondary' onClick={() => window.history.back()}><FiArrowLeft /> Voltar</Button>
            </div>
          </div>
        </section>

        <section>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Cards>
              <div className='row'>
                <div className={(usuarioId ? 'col-md-6' : 'col-md-12') + ' col-sm-12 mb-3'}>
                  <div className='form-group'>
                    <label htmlFor='nome'>Nome <CampoObrigatorio /></label>
                    <input className='form-control' type='text' {...register('nome', { required: 'Informe o nome completo!' })} />
                    <span className='text-danger text-small d-block mb-2 mt-2'>{errors.nome?.message}</span>
                  </div>
                </div>
                <div className={(usuarioId ? 'col-md-6' : 'col-md-6') + ' col-sm-12 mb-3'}>
                  <div className='form-group'>
                    <label htmlFor='usuario'>E-mail <CampoObrigatorio /></label>
                    <input
                      className='form-control'
                      id='email'
                      type='email'
                      {...register('email', { required: 'Informe o seu email!' })}
                      readOnly={usuarioId ? 'readonly' : ''}
                    />
                    <span className='text-danger text-small d-block mb-2 mt-2'>{errors.email?.message}</span>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6 col-sm-12 mb-3' style={showEditar ? { display: 'block' } : { display: 'none' }}>
                  <div className='form-group'>
                    <label htmlFor='status'>Status</label>
                    <select
                      className='form-select'
                      {...register('status')}
                    >
                      <option value='ATIVADO'>ATIVADO</option>
                      <option value='CANCELADO'>CANCELADO</option>
                    </select>
                    <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.status?.message}</span>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6 col-sm-12 mb-3' style={showEditar ? { display: 'none' } : { display: 'block' }}>
                  <div className='form-group'>
                    <label htmlFor='status'>Senha <CampoObrigatorio /></label>
                    <div className='input-group mb-3'>
                      <input className='form-control' type='text' {...register('senha', { required: !showEditar })} />
                    </div>
                    <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.status?.message}</span>
                  </div>
                </div>
              </div>

              <div className='col-md-6 col-sm-12 mb-3' style={{ display: 'none' }}>
                <div className='form-group'>
                  <label htmlFor='tipo_usuario'>Tipo de usuário</label>
                  <select
                    className='form-select'
                    {...register('tipo_usuario')}
                  >
                    <option value='COMUM'>COMUM</option>
                    <option value='DESENVOLVEDOR'>DESENVOLVEDOR</option>
                    <option value='GERENCIAL'>GERENCIAL</option>
                  </select>
                  <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.tipo_usuario?.message}</span>
                </div>
              </div>

            </Cards>
            <div className='row'>
              <div className='col'></div>
              <div className='col-auto'>
                <Button variant="success" className="mb-3" type='submit' disabled={loading}>
                  {
                    loading ? <><Spinner animation='border' size='sm' /> Aguarde...</> : <><FiCheck /> Salvar</>
                  }
                </Button>
              </div>
            </div>
          </form>

          {
            usuarioId
              ? <UsuarioAlterarSenha usuarioId={usuarioId} usuario={document.getElementById('email')?.value} />
              : <></>
          }
        </section>
      </Container>
    </>
  )
}

export { UsuarioCadastro }
