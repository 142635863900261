import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Container, Spinner } from 'react-bootstrap'
import { FiArrowLeft, FiCheck } from 'react-icons/fi'
import { useHistory, useParams } from 'react-router-dom';

import { Cards } from '../../components/cards';
import { setValueForm } from '../../libs/setValueForm';
import GrupoAcaoService from '../../services/grupoAcao'
import { ModalAviso } from '../../components/modalAviso';
import { useAuth } from '../../services/auth';

function GrupoAcaoCadastro(props) {
  const { register, handleSubmit, formState: { errors }, reset, setValue, setFocus } = useForm();
  const { SignOutForce } = useAuth()
  const [showStatus, setShowStatus] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalAviso, setModalAviso] = useState(false)
  const [showAlertErroServidor, setShowAlertErroServidor] = useState(false)
  const [alertError, setShowAlertError] = useState(false)
  const [carregandoPagina, setCarregandoPagina] = useState(true)
  const [messageError, setMessageError] = useState('')
  const { grupoId } = useParams()
  const history = useHistory()
  let strModalMensagem = 'Registro salvo com sucesso!'

  useEffect(() => {
    if (!grupoId) {
      setCarregandoPagina(false)
      return;
    }

    async function loadData() {
      try {
        setShowStatus(true)
        GrupoAcaoService
          .list(grupoId)
          .then((data) => {
            setCarregandoPagina(false)
            setValueForm(setValue, data)
          })
      } catch (error) {
        setCarregandoPagina(true)
      }
    }

    if (carregandoPagina) {
      loadData()
    }
  }, [grupoId, setValue, carregandoPagina])

  function handleErrors(error) {
    if (error.code) {
      switch (error.code) {
        case 0:
          setShowAlertErroServidor(true)
          setLoading(false)
          setTimeout(() => {
            setShowAlertErroServidor(false)
          }, 5000)
          break
        case 403:
          SignOutForce()
          window.location.reload()
          break
        case 409:
          setMessageError(error.message)
          setShowAlertError(true)
          setLoading(true)
          setTimeout(() => {
            setShowAlertError(false)
            setLoading(false)
          }, 3000)
          break
        default:
          setMessageError(error.message)
          setShowAlertError(true)
          setTimeout(() => {
            setShowAlertError(false)
            setLoading(false)
          }, 3000)
          break
      }
    } else {
      setMessageError(error.message)
      setShowAlertError(true)
      setTimeout(() => {
        setShowAlertError(false)
        setLoading(false)
      }, 3000)
    }
  }

  const onSubmit = (data, e) => {
    e.preventDefault()

    if (!grupoId) {
      async function Registrar() {
        try {
          GrupoAcaoService
            .register({
              nome: data.nome
            })
            .then(() => {
              setModalAviso(true)
              setLoading(false)
              setTimeout(() => {
                setModalAviso(false)
              }, 2000)
              reset();
              setFocus('nome')
            })
            .catch((error) => {
              handleErrors(error)
            })
        } catch (error) {
          setLoading(false)
        }
      }

      setLoading(true)
      Registrar()
    } else {
      async function Editar() {
        try {
          GrupoAcaoService
            .update(grupoId, {
              nome: data.nome,
              status: data.status
            })
            .then(() => {
              setModalAviso(true)
              setTimeout(() => {
                setLoading(false)
                setModalAviso(false)
                history.push('/grupo-acao')
              }, 2000);
            })
            .catch((error) => {
              handleErrors(error)
            })
        } catch (error) {
          setLoading(false)
        }
      }

      setLoading(true)
      Editar()
    }
  };


  return (
    <div>
      <Container className='col-lg-8 col-md-12 col-sm-12  mt-5' fluid>
        <ModalAviso show={modalAviso} message={strModalMensagem} color='bg-success' backdrop={false} />
        <ModalAviso show={showAlertErroServidor} message='Verifique sua conexão com a internet.' color='bg-danger' backdrop={false} />
        <ModalAviso show={alertError} message={messageError} color='bg-warning' backdrop={false} />
        <ModalAviso show={carregandoPagina} message='Carregando registro...' color='bg-light' backdrop={true} />
        <div className='row'>
          <div className='col mb-3'>
            <h4>Cadastro grupo de ação</h4>
          </div>
          <div className='col-auto'>
            <Button variant='outline-secondary' onClick={() => window.history.back()}><FiArrowLeft /> Voltar</Button>
          </div>
        </div>
        <section>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Cards>
              <div className='row'>
                <input className='form-control' id='codigoDescricao' type='hidden' value='' />
                <div className='col-sm-12 col-md-6 mb-3'>
                  <div className='form-group'>
                    <label htmlFor='nome'>Nome do grupo</label>
                    <input
                      className='form-control'
                      id='nome'
                      type='text'
                      {...register('nome', { required: 'Informe o nome do grupo de ação!' })}
                      autoFocus
                    />
                    <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.nome?.message || (errors?.nome && 'Descrição obrigatória!')}</span>
                  </div>

                  <div className='col-sm-12 col-md-12 mb-3'>
                    <div className='form-group' style={showStatus ? { display: 'block' } : { display: 'none' }}>
                      <label htmlFor='status'>Selecionar status</label>
                      <select
                        className='form-select'
                        {...register('status')}
                      >
                        <option value='ATIVADO'>ATIVADO</option>
                        <option value='CANCELADO'>CANCELADO</option>
                      </select>
                      <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.status?.message}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Cards>
            <div className='row'>
              <div className='col'></div>
              <div className='col-auto'>
                <button className='btn btn-success mb-3' type='submit' disabled={loading}>
                  {
                    loading ? <><Spinner animation='border' size='sm' /> Aguarde...</> : <><FiCheck /> Salvar</>
                  }
                </button>
              </div>
            </div>
          </form>
        </section>
      </Container>
    </div >
  )
}

export { GrupoAcaoCadastro }
