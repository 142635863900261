import ConfigURL from '../config/api'
import security from './security'

const cliente = {}

cliente.register = body => {
  return security.validation(ConfigURL.axios.post(ConfigURL.api.cliente.post.register, body))
}

cliente.listAll = (filter) => {
  if (filter) {
    return security.validation(ConfigURL.axios.get(ConfigURL.api.cliente.get.listAll + '?sort=nome&fields=' + filter))
  } else {
    return security.validation(ConfigURL.axios.get(ConfigURL.api.cliente.get.listAll + '?sort=nome'))
  }
}
cliente.listAllAniversariantes = (filter, status) => {
  return security.validation(ConfigURL.axios.get(ConfigURL.api.cliente.get.listAllAniversariantes + '?data_nascimento_mes=' + filter + '&status=' + status))
}

cliente.filterAll = body => {
  return security.validation(ConfigURL.axios.get(`${ConfigURL.api.cliente.get.listAll}?sort=nome&data_inicial=${body.data_inicial}&data_final=${body.data_final}&status=${body.status}&cpf=${body.cpf}&grupo_acao=${body.grupo_acao}&possui_acompanhamento=${body.possui_acompanhamento}&fields=${body.fields}&bairro=${body.bairro}`))
}

cliente.list = (id) => {
  return security.validation(ConfigURL.axios.get(`${ConfigURL.api.cliente.get.list}/${id}`))
}

cliente.update = (id, body) => {
  return security.validation(ConfigURL.axios.put(`${ConfigURL.api.cliente.put.update}/${id}`, body))
}

cliente.listCPF = (cpf) => {
  return security.validation(ConfigURL.axios.get(`${ConfigURL.api.cliente.get.listAll}?cpf=${cpf}&fields=id,nome,cpf,grupo_acao`))
}

// FOTOS
cliente.registerFoto = (id, formData) => {
  return security.validation(ConfigURL.axios.post(ConfigURL.api.cliente.post.register + '/' + id + '/foto', formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }))
}

cliente.deleteFoto = (id, file) => {
  return security.validation(ConfigURL.axios.delete(ConfigURL.api.cliente.post.register + '/' + id + '/foto/' + file))
}

cliente.axios = ConfigURL.axios

export default cliente