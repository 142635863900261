import React from 'react'
import { FiBriefcase, FiThumbsUp, FiUser, FiUsers } from 'react-icons/fi'

export const NavbarData = [
  {
    title: 'Grupo',
    link: '/grupo-acao',
    icon: <FiUsers />,
    items_qtd: 1
  },
  {
    title: 'Acompanhamento',
    link: '/acompanhamento/beneficio',
    icon: <FiThumbsUp />,
  },
  {
    title: 'Cliente',
    link: '/cliente',
    icon: <FiBriefcase />,
    items: [
      {
        title: 'Listar',
        link: '/cliente',
      },
      {
        title: 'Aniversariantes',
        link: '/aniversariantes',
      },
    ]
  },
  {
    title: 'Usuário',
    link: '/usuario',
    icon: <FiUser />,
  }
]