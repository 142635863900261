import React, { useState } from 'react'
import cep from 'cep-promise'
import { FiSearch } from 'react-icons/fi';

import { Cards } from '../../components/cards';
import { ModalAviso } from '../../components/modalAviso';

function ClienteCadastroEndereco({ register, errors, setValue, carregandoPagina, setCarregandoPagina }) {
  const [alertError, setShowAlertError] = useState()

  async function consultaCep(cepConsulta) {
    try {
      setCarregandoPagina(true)
      cep(cepConsulta)
        .then((res) => {
          setValue('endereco_cidade', res.city)
          setValue('endereco_bairro', res.neighborhood)
          setValue('endereco_estado', res.state)
          setValue('endereco_logradouro', res.street)
          setCarregandoPagina(false)
        })
        .catch((error) => {
          setCarregandoPagina(false)
          setShowAlertError(true)
          setTimeout(() => {
            setShowAlertError(false)
          }, 2000)
        })
    } catch (err) {
      errors = err
    }
  }

  const handleClickCep = () => {
    consultaCep(document.getElementById('endereco_cep').value);
    return;
  }

  return (
    <Cards>
      <ModalAviso show={carregandoPagina} message='Carregando registro...' color='bg-light' backdrop={true} />
      <ModalAviso show={alertError} message='Ops! Não encontramos o CEP. Tente outro' color='bg-danger' backdrop={false} />
      <div className='row'>
        <div className='col'>
          <h5>Endereço</h5>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-4 col-sm-12 mb-3'>
          <label htmlFor='endereco_cep'>CEP</label>
          <div className='input-group'>
            <input
              className='form-control'
              id='endereco_cep'
              type='text'
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  e.preventDefault()
                  e.stopPropagation()
                  consultaCep(e.target.value)
                }
              }}
              {...register('endereco_cep')}
            />
            <button className='btn btn-outline-secondary' id='btnCEP' type='button' onClick={handleClickCep} title='Pesquisar CEP' >
              <FiSearch />
            </button>
          </div>
          <span className='text-danger text-small d-block mt-1'>{errors?.endereco?.cep?.message}</span>
        </div>

      </div>
      <div className='row'>
        <div className='col-md-8 col-sm-12 mb-3'>
          <label htmlFor='endereco_cidade'>Cidade</label>
          <input className='form-control' type='text' {...register('endereco_cidade')} />
          <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.endereco?.cidade?.message}</span>
        </div>

        <div className='col-md-4 col-sm-12 mb-3'>
          <label htmlFor='endereco_estado'>Estado (UF)</label>
          <input className='form-control' type='text' {...register('endereco_estado')} />
          <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.endereco?.estado?.message}</span>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 col-sm-12 mb-3'>
          <label htmlFor='endereco_logradouro'>Logradouro</label>
          <input className='form-control' type='text' {...register('endereco_logradouro')} />
          <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.endereco?.logradouro?.message}</span>
        </div>

        <div className='col-md-2 col-sm-12 mb-3'>
          <label htmlFor='endereco_numero'>Número </label>
          <input className='form-control' type='text' {...register('endereco_numero')} />
          <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.endereco?.numero?.message}</span>
        </div>

        <div className='col-md-4 col-sm-12 mb-3'>
          <label htmlFor='endereco_complemento'>Complemento</label>
          <input className='form-control' type='text' {...register('endereco_complemento')} />
        </div>
      </div>

      <div className='row'>
        <div className='col col-sm-12 mb-3'>
          <label htmlFor='endereco_bairro'>Bairro</label>
          <input className='form-control' type='text' {...register('endereco_bairro')} />
          <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.endereco?.bairro?.message}</span>
        </div>
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <div className='form-check'>
            <input className='form-check-input' type='checkbox' id='endereco_autoriza_visita' value={true} {...register('endereco_autoriza_visita')} />
            <label className='form-check-label' htmlFor='endereco_autoriza_visita'>
              Autoriza visita
            </label>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <label htmlFor='endereco_observacao'>Observação</label>
          <textarea className='form-control' rows='5' {...register('endereco_observacao')} />
        </div>
      </div>
    </Cards>
  )
}

export { ClienteCadastroEndereco }
