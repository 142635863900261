import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { FiPlus } from 'react-icons/fi'

import TableWithSubcomponent from '../../components/tableWithSubcomponent'
import './grupoAcao.css'

function ModalGrupoAcao(props) {
  useEffect(() => {
    props.setDataTabela(props.db)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.db])

  const addTagGrupo = text =>
    props.setGrupoNovo(
      [...props.grupoNovo, {
        id: text.id,
        nome: text.nome
      }]
    )

  const columns = [
    {
      Header: 'Descrição',
      accessor: 'nome'
    },
    {
      Header: 'Ações',
      id: 'deletar',
      accessor: (str) => 'deletar',

      Cell: (tableProps) => (
        <>
          <button className='btn btn-sm btn-outline-success'
            onClick={() => {
              let dataCopy = props.dataTabela

              addTagGrupo(props.dataTabela[tableProps.row.index])

              dataCopy.splice(tableProps.row.index, 1)
              props.setDataTabela(dataCopy)
            }}
          >
            <FiPlus />
          </button>
        </>
      )
    }
  ];

  if (props.show) {
    return (
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        id='modalGrupoAcao'
        scrollable='true'
      >
        <Modal.Header custom closeButton closeLabel=''>
          <Modal.Title>Grupo de ação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableWithSubcomponent columns={columns} data={props.dataTabela} size={5} />
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100">
            {props.renderGrupo()}
          </div>
        </Modal.Footer>
      </Modal>
    )
  }

  return (<></>)
}

export default React.memo(ModalGrupoAcao)
