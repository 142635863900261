import React from 'react'

import { Cards } from '../../components/cards'
import FormValidate from '../../libs/form.validate'

function ClienteCadastroMoradia({ register, setValue }) {

  return (
    <Cards>
      <div className='row'>
        <div className='col'>
          <h5>Moradia</h5>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-3 col-sm-12 mb-3'>
          <label htmlFor='moradia_possui'>Possui</label>
          <select
            className='form-select'
            {...register('moradia_possui')}
          >
            <option value={false}>Não</option>
            <option value={true}>Sim</option>
          </select>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-3 col-sm-12 mb-3' >
          <label htmlFor='moradia_tipo'>Tipo da moradia</label>
          <select
            className='form-select'
            {...register('moradia_tipo')}
          >
            <option value='ALVENARIA'>Alvenaria</option>
            <option value='MADEIRA'>Madeira</option>
            <option value='MISTA'>Mista</option>
          </select>
        </div>

        <div className='col-md-3 col-sm-12 mb-3' >
          <label htmlFor='moradia_titularidade'>Titularidade</label>
          <select
            className='form-select'
            {...register('moradia_titularidade')}
          >
            <option value='ALUGADA'>Alugada</option>
            <option value='CEDIDA'>Cedida</option>
            <option value='FINANCIADA'>Financiada</option>
            <option value='PROPRIA'>Própria</option>
          </select>
        </div>



        <div className='col-md-3 col-sm-12 mb-3' >
          <label htmlFor='moradia_numero_comodos'>Número de cômodos</label>
          <input className='form-control' type='number' {...register('moradia_numero_comodos')} />
        </div>

        <div className='col-md-3 col-sm-12 mb-3' >
          <label htmlFor='moradia_gasto_aluguel'>R$ Despesa moradia</label>
          <input
            className='form-control'
            type='text'
            {...register('moradia_gasto_aluguel')}
            onKeyUp={
              (e) => {
                setValue(e.target.name, FormValidate.onChange.Moeda(e.target?.value))
              }
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12 col-sm-12 mt-3 mb-4'>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='checkbox' id='moradia_saneamento' value={true} {...register('moradia_saneamento')} />
            <label className='form-check-label' htmlFor='moradia_saneamento'>Saneamento</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='checkbox' id='moradia_esgoto' value={true} {...register('moradia_esgoto')} />
            <label className='form-check-label' htmlFor='moradia_esgoto'>Esgoto</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='checkbox' id='moradia_agua' value={true} {...register('moradia_agua')} />
            <label className='form-check-label' htmlFor='moradia_agua'>Água</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='checkbox' id='moradia_energia' value={true} {...register('moradia_energia')} />
            <label className='form-check-label' htmlFor='moradia_energia'>Energia</label>
          </div>

          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='checkbox' id='moradia_banheiro' value={true} {...register('moradia_banheiro')} />
            <label className='form-check-label' htmlFor='moradia_banheiro'>Banheiro</label>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <h5>Gastos com moradia</h5>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-3 col-sm-12 mb-3' >
          <label htmlFor='moradia_gasto_agua'>R$ Água</label>
          <input
            className='form-control'
            type='text'
            {...register('moradia_gasto_agua')}
            onKeyUp={
              (e) => {
                setValue(e.target.name, FormValidate.onChange.Moeda(e.target?.value))
              }
            }
          />
        </div>

        <div className='col-md-3 col-sm-12 mb-3' >
          <label htmlFor='moradia_gasto_energia'>R$ Energia</label>
          <input
            className='form-control'
            type='text'
            {...register('moradia_gasto_energia')}
            onKeyUp={
              (e) => {
                setValue(e.target.name, FormValidate.onChange.Moeda(e.target?.value))
              }
            }
          />
        </div>

        <div className='col-md-3 col-sm-12 mb-3' >
          <label htmlFor='moradia_gasto_gas'>R$ Gás</label>
          <input
            className='form-control'
            type='text'
            {...register('moradia_gasto_gas')}
            onKeyUp={
              (e) => {
                setValue(e.target.name, FormValidate.onChange.Moeda(e.target?.value))
              }
            }
          />
        </div>

        <div className='col-md-3 col-sm-12 mb-3' >
          <label htmlFor='moradia_gasto_alimentacao'>R$ Alimentação</label>
          <input
            className='form-control'
            type='text'
            {...register('moradia_gasto_alimentacao')}
            onKeyUp={
              (e) => {
                setValue(e.target.name, FormValidate.onChange.Moeda(e.target?.value))
              }
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-md-3 col-sm-12 mb-3' >
          <label htmlFor='moradia_gasto_remedio'>R$ Remédios</label>
          <input
            className='form-control'
            type='text'
            {...register('moradia_gasto_remedio')}
            onKeyUp={
              (e) => {
                setValue(e.target.name, FormValidate.onChange.Moeda(e.target?.value))
              }
            }
          />
        </div>

        <div className='col-md-3 col-sm-12 mb-3' >
          <label htmlFor='moradia_gasto_internet'>R$ Internet</label>
          <input
            className='form-control'
            type='text'
            {...register('moradia_gasto_internet')}
            onKeyUp={
              (e) => {
                setValue(e.target.name, FormValidate.onChange.Moeda(e.target?.value))
              }
            }
          />
        </div>

        <div className='col-md-3 col-sm-12 mb-3' >
          <label htmlFor='moradia_gasto_educacao'>R$ Educação</label>
          <input
            className='form-control'
            type='text'
            {...register('moradia_gasto_educacao')}
            onKeyUp={
              (e) => {
                setValue(e.target.name, FormValidate.onChange.Moeda(e.target?.value))
              }
            }
          />
        </div>

        <div className='col-md-3 col-sm-12 mb-3' >
          <label htmlFor='moradia_gasto_outros'>R$ Outras despesas</label>
          <input
            className='form-control'
            type='text'
            {...register('moradia_gasto_outros')}
            onKeyUp={
              (e) => {
                setValue(e.target.name, FormValidate.onChange.Moeda(e.target?.value))
              }
            }
          />
        </div>

      </div>

      <div className='row'>
        <div className='col mb-3'>
          <label htmlFor='moradia_observacao'>Observação</label>
          <textarea className='form-control' rows='5' {...register('moradia_observacao')} />
        </div>
      </div>
    </Cards>
  )
}

export { ClienteCadastroMoradia }
