import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FiFilter, FiTrash2 } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'

function ModalGrupoAcaoFiltro(props) {

  const history = useHistory()

  const redirectUrl = () => {
    const objStatus = document.getElementById('grupoStatus').value
    history.push('/grupo-acao?status=' + objStatus)
    props.onHide()
  }


  return (
    <>
      <Modal {...props} size='md' centered>
        <Modal.Header>
          <Modal.Title>Filtrar grupos de ação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col'>
              <div className='form-group'>
                <label htmlFor='grupoStatus'>Status</label>
                <select className='form-select' id='grupoStatus' {...props.register('status')}>
                  <option value='ativado'>Ativado</option>
                  <option value='cancelado'>Cancelado</option>
                </select>
              </div>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-secondary' onClick={props.cleanFilter}>
            <FiTrash2 /> Limpar filtro
          </Button>
          <Button variant='primary' onClick={() => { redirectUrl() }}>
            <FiFilter /> Filtrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export { ModalGrupoAcaoFiltro }
