import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <InputGroup className='mb-3 col text-right d-print-none'>
      <FormControl
        id='txtFiltro'
        type='text'
        value={filter || ''}
        onChange={e => setFilter(e.target.value)}
        placeholder='Digite algo para pesquisar..'
        autoFocus
      />
      <InputGroup.Text htmlFor='txtFiltro'><FiSearch /></InputGroup.Text>
    </InputGroup>
  );
}
