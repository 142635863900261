const lib = {}

lib.setItem = (key, value) => {
  return new Promise(resolve => {
    localStorage.setItem(key, value)
    resolve()
  })
}

lib.getItem = key => {
  return new Promise(resolve => {
      resolve(localStorage.getItem(key))
  })
}

lib.clear = () => {
  return new Promise(resolve => {
      localStorage.clear()
      resolve()
  })
}

lib.removeItem = key => {
  return new Promise(resolve => {
      localStorage.removeItem(key)
      resolve()
  })
}

lib.AremoveItem = ([...key]) => {
  return new Promise(resolve => {
      key.map(k => {
          localStorage.removeItem(k)
          return []
      })
      resolve()
  })
}

export default lib
