import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FiAtSign, FiLock } from 'react-icons/fi'
import { Link, useHistory } from 'react-router-dom'

import { useAuth } from '../../services/auth'
import AsyncStorage from '../../storage/asyncStorage'
import Logo from '../../public/login_logo.svg'

import './login.css'
import { ModalAviso } from '../../components/modalAviso'

function Login() {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm()
  const history = useHistory()
  const { SignIn } = useAuth()
  const [usuario, setUsuario] = useState('')
  const [modalAviso, setModalAviso] = useState(false)
  const [showAlertErroServidor, setShowAlertErroServidor] = useState(false)
  const [alertError, setShowAlertError] = useState(false)
  const [remember, setRemember] = useState(false)
  const [messageError, setMessageError] = useState('')

  function handleSignIn(data, e) {
    e.preventDefault();

    async function login() {
      try {
        SignIn(data.usuario, data.senha)
          .then(async () => {
            Remember(data.usuario)
            setModalAviso(true)
            setTimeout(() => {
              setModalAviso(false)
            }, 500)
            history.push('/cliente')
          })
          .catch(error => {
            if (error.code) {
              switch (error.code) {
                case 0:
                  setShowAlertErroServidor(true)
                  setMessageError(error.message)
                  setTimeout(() => {
                    setShowAlertErroServidor(false)
                  }, 5000)
                  setUsuario('')
                  break;
                case 429:
                  setShowAlertError(true)
                  setMessageError(error.message)
                  setTimeout(() => {
                    setShowAlertError(false)
                  }, 3000)
                  break;
                case 400:
                  setShowAlertError(true)
                  setMessageError(error.message)
                  setTimeout(() => {
                    setShowAlertError(false)
                  }, 3000)
                  break;
                default:
                  setMessageError(error.message)
                  setShowAlertError(true)
                  setTimeout(() => {
                    setShowAlertError(false)
                  }, 3000)
                  break;
              }
            } else {
              if (error.error) {
                setMessageError(error.message)
                setShowAlertError(true)
              }
            }
          })
      } catch (error) {
        setRemember(false)
        setUsuario('')
        AsyncStorage.removeItem('@LMAuth:remember')

      }
    }

    login()
  };

  async function Remember(usuario) {
    if (remember) {
      await AsyncStorage.setItem('@LMAuth:remember', usuario)
    } else {
      await AsyncStorage.removeItem('@LMAuth:remember')
    }
  }

  useEffect(() => {
    async function setRememberUser() {
      const user = await AsyncStorage.getItem('@LMAuth:remember')

      if (user) {
        setValue('usuario', user)
        setUsuario(user)
        setRemember(true)
      }
    }
    setRememberUser()
  }, [setUsuario, setRemember, setValue])

  return (
    <>
      <Container className='p-5'>
        <ModalAviso show={modalAviso} message='' color='bg-success' backdrop={false} />
        <ModalAviso show={showAlertErroServidor} message='Verifique sua conexão com a internet.' color='bg-danger' backdrop={true} />
        <ModalAviso show={alertError} message={messageError} color='bg-warning' backdrop={false} />
        <div className='col-md-12 col-sm-12 mb-4'>
          <img className='logo' src={Logo} alt='Logo ABBA Church' />
        </div>
        <form onSubmit={handleSubmit(handleSignIn)}>
          <div className='row'>
            <div className='col-md-12 col-sm-12 mb-3 form-group'>
              <label htmlFor='email'><FiAtSign /> E-mail</label>
              <input className='form-control' type='email' {...register('usuario', { required: true })} autoFocus />
              <span className='text-danger text-small d-block mb-2'>{errors.usuario?.type === 'required' && 'E-mail obrigatório'}</span>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 col-sm-12 mb-2 form-group'>
              <label htmlFor='senha'><FiLock /> Senha</label>
              <input
                className='form-control'
                id='senha'
                type='password'
                {...register('senha', {
                  required: 'Senha obrigatória!',
                  minLength: {
                    value: 6,
                    message: 'Tamanho mínimo da senha: 6 caracteres.'
                  }
                })}
              />
              <span className='text-danger text-small d-block mt-1 mb-2'>{errors?.senha?.message || (errors?.senha && 'Senha inválida!')}</span>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <div className='form-check'>
                <input className='form-check-input' id='txtLembrarEmail' type='checkbox' checked={remember} value={usuario} onChange={(e) => { setRemember(e.target.checked) }} />
                <label className='form-check-label' htmlFor='txtLembrarEmail' > Lembrar e-mail</label>
              </div>
            </div>
            <div className='col-auto mt-1'>
              <Link id='recuperarSenha' to='/recuperar-senha'>Recuperar senha</Link>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <input className='btn btn-lg btn-dark form-control mt-3' id='btnLogin' type='submit' value='Login' />
            </div>
          </div>
        </form>
      </Container>
    </>
  )
}

export { Login }