import configURL from '../config/api'
import security from './security'

const usuario = {}

usuario.register = body => {
  return security.validation(configURL.axios.post(configURL.api.usuario.post.register, body))
}

usuario.listAll = (fields) => {
  if (fields) {
    return security.validation(configURL.axios.get(configURL.api.usuario.get.listAll + '?sort=nome&fields=' + fields))
  } else {
    return security.validation(configURL.axios.get(configURL.api.usuario.get.listAll + '?sort=nome'))
  }

}

usuario.list = (id) => {
  return security.validation(configURL.axios.get(`${configURL.api.usuario.get.list}/${id}`))
}

usuario.update = (id, body) => {
  return security.validation(configURL.axios.put(`${configURL.api.usuario.put.update}/${id}`, body))
}

usuario.patch = (id, body) => {
  return security.validation(configURL.axios.patch(`${configURL.api.usuario.put.update}/${id}`, body))
}

usuario.updatePassword = (body) => {
  return security.validation(configURL.axios.post(`${configURL.api.auth.put.updatePassword}`, body))
}

usuario.relatorioSimples = () => {
  return configURL.axios.get(`${configURL.api.usuario.get.relatorioSimples}`, { responseType: 'blob' })
}

export default usuario