import React from 'react'
import { Container } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FiKey, FiLock } from 'react-icons/fi';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import './recuperarSenha.css'

function RecuperarSenha() {
  const validationSchema = yup.object().shape({
    codigo: yup.string()
      .required('Código necessário!'),
    novaSenha: yup.string()
      .min(6, 'A senha deve conter no mínimo 6 caracteres')
      .required('Nova senha obrigatória'),
    repetirSenha: yup.string()
      .oneOf([yup.ref('novaSenha'), null], 'As senhas são diferentes!')
      .required('Confirme sua senha'),
  });

  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, formState: { errors } } = useForm(formOptions);

  const onSubmit = (data) => {

  };


  return (
    <div className='jumbotron d-flex align-item-center min-vh-100'>
      <Container className='mt-5 mb-5'>
        <div className='row'>
          <div className='col mb-3'>
            <h3 className='text-center mb-3'>Recuperação de senha</h3>
            <p className='text-danger'>Um código de segurança foi enviado para o seu e-mail</p>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-3 form-group'>
            <label htmlFor='codigo'><FiKey /> Código</label>
            <input
              className='form-control'
              type='text'
              name='codigo'
              {...register('codigo')}
            />
            <span className='text-danger text-small d-block mb-2 mt-2'>{errors.codigo?.message}</span>
          </div>
          <div className='mb-3 form-group'>
            <label htmlFor='senha'><FiLock /> Nova senha</label>
            <input
              className='form-control'
              id='novaSenha'
              type='text'
              {...register('novaSenha', { required: 'Campo obrigatório' })}
            />
            <span className='text-danger text-small d-block mb-2 mt-2'>{errors.novaSenha?.message}</span>
          </div>
          <div className='mb-3 form-group'>
            <label htmlFor='senha'><FiLock /> Repetir senha</label>
            <input
              className='form-control'
              id='repetirSenha'
              type='text'
              {...register('repetirSenha', { required: 'Confirme sua nova senha!' })}
            />
            <span className='text-danger text-small d-block mb-2 mt-2'>{errors.repetirSenha?.message}</span>
          </div>
          <div>
            <input className='btn btn-lg btn-dark form-control mt-3' id='btnLogin' type='submit' value='Recuperar senha' />
          </div>

        </form>
      </Container>
    </div>
  )
}

export default RecuperarSenha
