import React, { useEffect, useMemo, useState } from 'react'
import { Button, Container, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FiEdit2, FiFilter, FiPlus, FiXOctagon, FiEye } from 'react-icons/fi'
import { Link, useHistory, useLocation } from 'react-router-dom'

import TableWithSubcomponent from '../../components/tableWithSubcomponent'
import AcompanhamentoBeneficioService from '../../services/acompanhamentoBeneficio'
import { Cards } from '../../components/cards'
import { FormatDateToBR } from '../../libs/formatDate'
import { setValueForm } from '../../libs/setValueForm'
import FormSanitize from '../../libs/form.sanitize'
import FormValidate from '../../libs/form.validate'

import { ModalFiltroAcompanhamento } from './modalFiltroAcompanhamento'
import { ModalAviso } from '../../components/modalAviso'
import { useAuth } from '../../services/auth'

function AcompanhamentoBeneficioLista2() {
  const { register, getValues, reset, setValue } = useForm();
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const { SignOutForce } = useAuth()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [modalFiltroAcompanhamento, setModalFiltroAcompanhamento] = useState(false)
  const [btnFiltroAtivo, setBtnFiltroAtivo] = useState(false)
  const [modalAvisoErroServidor, setModalAvisoErroServidor] = useState(false)
  const [modalAvisoAlerta, setModalAvisoAlerta] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [ShowModalFinalizar, setShowModalFinalizar] = useState(false)
  const [idComposicaoBeneficioFinalizar, setIdComposicaoBeneficioFinalizar] = useState(0)
  const [grupoFiltro, setGrupoFiltro] = useState([])

  const [dataInicial, setDataInicial] = useState(searchParams.get('data_inicial'))
  const [dataFinal, setDataFinal] = useState(searchParams.get('data_final'))
  const [pessoaCPF, setPessoaCpf] = useState(searchParams.get('pessoa_cpf'))
  const [cliente, setCliente] = useState(searchParams.get('cliente'))
  const [tipoData, setTipoData] = useState(searchParams.get('tipo_data'))
  const [grupoAcao, setGrupoAcao] = useState(searchParams.get('grupo_acao'))

  const [filtro, setFiltro] = useState('&')
  const [renderFiltro, setRenderFiltro] = useState(false)

  useEffect(() => {
    setFiltro('&')

    setDataInicial(getValues('data_inicial'))
    setDataFinal(getValues('data_final'))
    setPessoaCpf(getValues('pessoa_cpf'))
    setCliente(getValues('cliente'))
    setTipoData(getValues('tipo_data'))
    setGrupoAcao(getValues('grupo_acao').id)

    if (grupoAcao === 'undefined' || grupoAcao === 'null') setGrupoAcao('')

    if (dataInicial) setFiltro(filtro + `data_inicial=${dataInicial}&`)
    if (dataFinal) setFiltro(filtro + `data_final=${dataFinal}&`)
    if (pessoaCPF) setFiltro(filtro + `pessoa_cpf=${pessoaCPF}&`)
    if (cliente) setFiltro(filtro + `cliente=${cliente}&`)
    if (tipoData) setFiltro(filtro + `tipo_data=${tipoData}&`)
    if (grupoAcao) setFiltro(filtro + `grupo_acao=${grupoAcao}&`)

    setRenderFiltro(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderFiltro])

  useEffect(() => {

    setGrupoFiltro({
      id: searchParams.get('grupo_acao'),
      nome: searchParams.get('grupo')
    })

    async function loadList() {
      setLoading(true)

      function handleErrors(error) {
        setLoading(false)

        if (error.code) {
          switch (error.code) {
            case 0:
              setModalAvisoErroServidor(true)
              setTimeout(() => {
                setModalAvisoErroServidor(false)
              }, 5000)
              break;
            case 401 || 403:
              SignOutForce()
              window.location.reload()
              break;
            case 409:
              setMessageError(error.message)
              setModalAvisoAlerta(true)
              setTimeout(() => {
                setModalAvisoAlerta(false)
              }, 3000)
              break;
            default:
              setMessageError(error.message)
              setModalAvisoAlerta(true)
              setTimeout(() => {
                setModalAvisoAlerta(false)
              }, 3000)
          }
        } else {
          setMessageError(error.message)
          setModalAvisoAlerta(true)
          setTimeout(() => {
            setModalAvisoAlerta(false)
          }, 4000)
        }
      }

      try {
        if (location.search) {
          const strDataInicial = searchParams.get('data_inicial')
          const strDataFinal = searchParams.get('data_final')
          let strPessoa = searchParams.get('pessoa_cpf')
          let strTipoData = searchParams.get('tipo_data')
          let grupoAcao = searchParams.get('grupo_acao')
          let grupoNome = searchParams.get('grupo')

          if (grupoAcao === 'undefined' || grupoAcao === 'null') {
            grupoAcao = ''
            grupoNome = ''
          }

          await handleFilter({
            data_inicial: strDataInicial,
            data_final: strDataFinal,
            tipo_data: strTipoData,
            pessoa_cpf: strPessoa,
            grupo_acao: grupoAcao
          });

          if (strTipoData === '') {
            strTipoData = 'cadastrado'
          }

          setValueForm(setValue, {
            data_inicial: strDataInicial,
            data_final: strDataFinal,
            tipo_data: strTipoData,
            pessoa: {
              nome: searchParams.get('cliente'),
              cpf: FormValidate.onChange.CPF(strPessoa)
            },
            grupo_acao: {
              nome: grupoNome
            }
          })

        } else {
          AcompanhamentoBeneficioService.listAll()
            .then(dados => {
              setData(dados.data)
              setLoading(false)
            })
            .catch(error => {
              handleErrors(error)
            })
        }
      } catch (error) {
        handleErrors(error)
        setLoading(false)
      }
    }

    if (loading) {
      loadList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])



  function FinalizarBeneficioShowModal(id) {
    setIdComposicaoBeneficioFinalizar(id)
    setShowModalFinalizar(true)
  }

  function FinalizarBeneficioHideModal() {
    setShowModalFinalizar(false)
  }

  function FinalizarBeneficio() {
    let Dados = {}

    async function Editar() {
      Dados.data_finalizacao = FormSanitize.DateHourNow().dateNow
      Dados.hora_finalizacao = FormSanitize.DateHourNow().hourNow

      AcompanhamentoBeneficioService
        .updatePatch(idComposicaoBeneficioFinalizar, Dados)
        .then(() => {
          FinalizarBeneficioHideModal()
          window.location.reload();
        })
        .catch((error) => {
          setLoading(false)
          setMessageError(error.message)
          setModalAvisoAlerta(true)
          FinalizarBeneficioHideModal()

          setTimeout(() => {
            setModalAvisoAlerta(false)
          }, 3000)
        })
    }

    setLoading(true)
    Editar()
  }

  const columns = useMemo(
    () => [
      {
        id: 'pessoa.nome',
        Header: () => (
          <div style={{ minWidth: '250px' }}>Cliente</div>
        ),
        accessor: 'pessoa.nome'
      },
      {
        id: 'data_cadastro',
        Header: () => (
          <div style={{ textAlign: 'center', width: '90px' }}>Data cadastro</div>
        ),
        accessor: dataCadastroProps => {
          return <div style={{ textAlign: 'center', width: '90px' }}>{FormatDateToBR(dataCadastroProps.data_cadastro)} {dataCadastroProps.hora_cadastro.slice(-8, 5)}</div>
        }
      },
      {
        id: 'data_recebimento',
        Header: () => (
          <div style={{ textAlign: 'center', width: '114px' }}>Recebimentos</div>
        ),
        Cell: totalRecebimentoProps => {
          return <div style={{ textAlign: 'center', width: '114px' }}>{totalRecebimentoProps.row.original.recebimentos?.length}</div>
        }
      },
      {
        id: 'data_finalizacao',
        Header: () => (
          <div style={{ textAlign: 'center', width: '90px' }}>Data finalização</div>
        ),
        accessor: dataFinalizacaoProps => {
          return <div style={{ textAlign: 'center', width: '90px' }}>{dataFinalizacaoProps.data_finalizacao ? `${FormatDateToBR(dataFinalizacaoProps.data_finalizacao)} ${dataFinalizacaoProps.hora_finalizacao.slice(-8, 5)}` : '-'}</div>
        }
      },
      {
        id: 'grupo_acao.nome',
        Header: () => (
          <div style={{ textAlign: 'center', width: '130px' }}>Grupo</div>
        ),
        accessor: dados => {
          return <div style={{ textAlign: 'center', width: '130px' }}>{dados.grupo_acao.nome}</div>
        }
      },
      {
        id: 'acoes',
        Header: () => (
          <div style={{ textAlign: 'center', width: '82px' }}>Ações</div>
        ),
        accessor: 'finalizar',
        Cell: (tableProps) => (
          <div className='align-center text-center'>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip id='tooltip-bottom'>
                  {(tableProps.row.original.data_finalizacao) ? <> Visualizar </> : <> Editar </>}
                </Tooltip>
              }
            >
              <Link
                className={(tableProps.row.original.data_finalizacao) ? 'btn btn-sm btn-outline-dark' : 'btn btn-sm btn-outline-success'}
                to={`/acompanhamento/beneficio/cadastro/${tableProps.row.original.id}`}
              >
                {(tableProps.row.original.data_finalizacao) ? <> <FiEye /> </> : <> <FiEdit2 /> </>}
              </Link>
            </OverlayTrigger>
            &nbsp;
            {
              (tableProps.row.original.data_finalizacao)
                ?
                <>
                </>
                :
                <>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip id='tooltip-bottom'>
                        Finalizar acompanhamento
                      </Tooltip>
                    }
                  >
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() => FinalizarBeneficioShowModal(tableProps.row.original.id)}
                    >
                      <FiXOctagon />
                    </Button>
                  </OverlayTrigger>
                </>
            }
          </div >
        )
      }
    ],
    []
  );

  const handleFilter = async (data) => {

    if (!data?.pessoa_cpf) data.pessoa_cpf = '';
    if (!data?.data_inicial) data.data_inicial = '';
    if (!data?.data_final) data.data_final = '';
    if (!data?.grupo_acao) data.grupo_acao = '';
    if (!data?.tipo_data) data.tipo_data = '';

    if (data.data_inicial && !data.data_final) {
      data.data_final = FormSanitize.DateHourNow().dateNow
      setValue('data_final', data.data_final)
    }

    data.fields = 'data_inicial,data_final,tipo_data,pessoa_cpf,grupo_acao'

    AcompanhamentoBeneficioService
      .filterAll(data)
      .then((dadosFiltrados) => {
        setData(dadosFiltrados.data)
        setModalFiltroAcompanhamento(false)
        setBtnFiltroAtivo(true)
        setLoading(false)
      })
      .catch((error) => {
        if (error.code) {
          switch (error.code) {
            case 0:
              setModalAvisoErroServidor(true)
              setTimeout(() => {
                setModalAvisoErroServidor(false)
              }, 5000)
              break;
            case 403:
              window.location.reload()
              break;
            case 409:
              setMessageError(error.message)
              setModalAvisoAlerta(true)
              setTimeout(() => {
                setModalAvisoAlerta(false)
              }, 3000)
              break;
            default:
              setMessageError(error.message)
              setModalAvisoAlerta(true)
              setTimeout(() => {
                setModalAvisoAlerta(false)
              }, 3000)
          }
        } else {
          setMessageError(error.message)
          setModalAvisoAlerta(true)
        }
      })
  }

  const cleanFilter = () => {
    reset()
    setBtnFiltroAtivo(false)
    window.location.assign(location.pathname)
  }

  function limparFiltros() {
    setValue('data_inicial', '')
    setValue('data_final', '')
    setValue('pessoa_cpf', '')
    setValue('tipo_data', '')
    setValue('grupo_acao', '')

    history.push('/acompanhamento/beneficio')
  }

  return (
    <>

      <Modal show={ShowModalFinalizar} onHide={() => FinalizarBeneficioHideModal()} centered>
        <Modal.Header closeButton>
          <Modal.Title>Acompanhamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>Finalizar o benefício?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => FinalizarBeneficioHideModal()}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={() => { FinalizarBeneficio() }}>
            Finalizar
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalAviso show={modalAvisoAlerta} message={messageError} color='bg-warning' backdrop={false} />
      <ModalAviso show={modalAvisoErroServidor} message='Verifique sua conexão com a internet.' color='bg-danger' backdrop={false} />

      <Container className='col-xl-9 col-lg-11 col-md-12 col-sm-12 mt-5' fluid>
        <form>
          <ModalFiltroAcompanhamento
            show={modalFiltroAcompanhamento}
            onHide={() => {
              setModalFiltroAcompanhamento(false)
              setLoading(true)
              setRenderFiltro(true)
            }}

            grupoFiltro={grupoFiltro}
            setGrupoFiltro={setGrupoFiltro}

            register={register}
            cleanFilter={cleanFilter}
            getValues={getValues}
            setValue={setValue}
            btnFiltroAtivo={btnFiltroAtivo}
          />
        </form>
        <Cards>
          <div className='row align-items-center'>
            <div className='col'>
              <h3>Acompanhamento de benefício</h3>
            </div>
            <div className='col-auto'>
              <button
                className={'btn ' + (btnFiltroAtivo ? 'btn-outline-dark' : 'btn-outline-secondary')}
                type='button'
                onClick={() => { setModalFiltroAcompanhamento(true) }}
              ><FiFilter /> Filtrar acompanhamentos
              </button>{' '}

              <button
                className={'btn ' + (btnFiltroAtivo ? 'btn-outline-dark' : 'btn-outline-secondary')}
                style={{ marginLeft: 6, marginRight: 6 }}
                type='button'
                onClick={() => {
                  limparFiltros()
                  setLoading(true)
                  setRenderFiltro(true)
                }}
              ><FiFilter /> Limpar filtro
              </button>
              <Link className='btn btn-success' to='/acompanhamento/beneficio/cadastro'><FiPlus /> Novo</Link>
            </div>
          </div>
        </Cards>
        <Cards>
          <TableWithSubcomponent
            columns={columns}
            data={data}
            loading={loading}
          />
        </Cards>
      </Container>
    </>
  )
}

export { AcompanhamentoBeneficioLista2 }
