import React, { useEffect, useMemo, useState } from 'react'
import { Container, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FiEdit2, FiFilter, FiPlus } from 'react-icons/fi'
import { Link, useHistory, useLocation } from 'react-router-dom'

import TableWithSubcomponent from '../../components/tableWithSubcomponent'
import { Cards } from '../../components/cards'
import { useAuth } from '../../services/auth'
import GrupoAcaoService from '../../services/grupoAcao'
import { useForm } from 'react-hook-form'
import { ModalGrupoAcaoFiltro } from './modalGrupoAcaoFiltro'
import { setValueForm } from '../../libs/setValueForm'
import { ModalAviso } from '../../components/modalAviso'

function GrupoAcaoLista() {
  const { register, reset, setValue, getValues } = useForm();
  const { SignOutForce } = useAuth()
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [btnFiltroAtivo, setBtnFiltroAtivo] = useState(false)
  const [modalGrupoAcaoFiltro, setModalGrupoAcaoFiltro] = useState(false)
  const [modalAvisoErroServidor, setModalAvisoErroServidor] = useState(false)
  const [modalAvisoAlerta, setModalAvisoAlerta] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [status, setStatus] = useState(searchParams.get('status'))
  const [filtro, setFiltro] = useState('&')
  const [renderFiltro, setRenderFiltro] = useState(false)

  useEffect(() => {
    setFiltro('&')
    setStatus(getValues('status'))

    if (status) setFiltro(filtro + `status=${status}&`)
    setRenderFiltro(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [renderFiltro])


  useEffect(() => {
    async function loadList() {
      function handleErrors(error) {
        if (error.code) {
          switch (error.code) {
            case 0:
              setModalAvisoErroServidor(true)
              setTimeout(() => {
                setModalAvisoErroServidor(false)
              }, 5000)
              break;
            case 403:
              SignOutForce()
              window.location.reload()
              break;
            case 409:
              setMessageError(error.message)
              setModalAvisoAlerta(true)
              setTimeout(() => {
                setModalAvisoAlerta(false)
              }, 3000)
              break;
            default:
              setMessageError(error.message)
              setModalAvisoAlerta(true)
              setTimeout(() => {
                setModalAvisoAlerta(false)
              }, 3000)
          }
        } else {
          setMessageError(error.message)
          setModalAvisoAlerta(true)
          setTimeout(() => {
            setModalAvisoAlerta(false)
          }, 3000)
        }
      }

      try {
        if (location.search) {
          let strStatus = searchParams.get('status')

          if (!strStatus) strStatus = 'ativado'

          await handleFilter({ status: strStatus })

          setValueForm(setValue, { status: strStatus })
        } else {
          GrupoAcaoService
            .listAll('id,nome,status')
            .then(dados => {
              setData(dados.data)
              setLoading(false)
            })
            .catch(error => {
              handleErrors(error)
              setLoading(false)
            })
        }
      } catch (error) {
        setLoading(false)
        handleErrors(error)
      }
    }

    if (loading) loadList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const columns = useMemo(
    () => [
      {
        Header: 'Descrição',
        accessor: 'nome'
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Ações',
        id: 'deletar',
        accessor: (str) => 'deletar',

        Cell: (tableProps) => (
          <>
            <>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip id='tooltip-bottom'>
                    Editar
                  </Tooltip>
                }
              >
                <Link
                  className='btn btn-sm btn-outline-success'
                  to={`/grupo-acao/cadastro/${tableProps.row.original.id}`}
                >
                  <FiEdit2 />
                </Link>

              </OverlayTrigger>
            </>
          </>
        )
      }
    ],
    []
  );

  const handleFilter = async (data) => {
    data.fields = 'id,nome,status'

    GrupoAcaoService
      .filterByStatus(data)
      .then((dadosFiltrados) => {
        setData(dadosFiltrados.data)
        setModalGrupoAcaoFiltro(false)
        setBtnFiltroAtivo(true)
        setLoading(false)
      })
      .catch((error) => {
        if (error.code) {
          switch (error.code) {
            case 0:
              setModalAvisoErroServidor(true)
              setTimeout(() => {
                setModalAvisoErroServidor(false)
              }, 5000)
              break;
            case 401 || 403:
              SignOutForce()
              window.location.reload()
              break;
            case 409:
              setMessageError(error.message)
              setModalAvisoAlerta(true)
              setTimeout(() => {
                setModalAvisoAlerta(false)
              }, 3000)
              break;
            default:
              setMessageError(error.message)
              setModalAvisoAlerta(true)
              setTimeout(() => {
                setModalAvisoAlerta(false)
              }, 3000)
          }
        } else {
          setMessageError(error.message)
          setModalAvisoAlerta(true)
        }
      })
  }

  const cleanFilter = () => {
    reset()
    setBtnFiltroAtivo(false)
    window.location.assign(location.pathname)
  }
  function limparFiltros() {
    setValue('status', '')
    history.push('/grupo-acao')
  }

  return (
    <>
      <ModalAviso show={modalAvisoAlerta} message={messageError} color='bg-warning' backdrop={false} />
      <ModalAviso show={modalAvisoErroServidor} message='Verifique sua conexão com a internet.' color='bg-danger' backdrop={false} />
      <Container className='col-lg-8 col-md-12 col-sm-12  mt-5' fluid>
        <form>
          <ModalGrupoAcaoFiltro
            show={modalGrupoAcaoFiltro}

            onHide={() => {
              setModalGrupoAcaoFiltro(false)
              setLoading(true)
              setRenderFiltro(true)
            }}

            register={register}
            cleanFilter={cleanFilter}
            setValue={setValue}
            btnFiltroAtivo={btnFiltroAtivo}
          />
        </form>
        <Cards>
          <div className='row align-items-center'>
            <div className='col'>
              <h3>Grupo de ação</h3>
            </div>
            <div className='col-auto'>
              <button
                className={'btn ' + (btnFiltroAtivo ? 'btn-outline-dark' : 'btn-outline-secondary')}
                type='button'
                onClick={() => { setModalGrupoAcaoFiltro(true) }}
              ><FiFilter /> Filtrar grupos
              </button>{' '}
              <button
                className={'btn ' + (btnFiltroAtivo ? 'btn-outline-dark' : 'btn-outline-secondary')}
                style={{ marginLeft: 6, marginRight: 6 }}
                type='button'
                onClick={() => {
                  limparFiltros()
                  setLoading(true)
                  setRenderFiltro(true)
                }}
              ><FiFilter /> Limpar filtro
              </button>
              <Link className='btn btn-success' to='/grupo-acao/cadastro'><FiPlus /> Novo</Link>
            </div>
          </div>
        </Cards>
        <Cards>
          <TableWithSubcomponent
            columns={columns}
            data={data}
            loading={loading}
          />
        </Cards>
      </Container>
    </>
  )
}

export { GrupoAcaoLista }
