import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { PerfilSenha } from './perfilSenha';
import { PerfilUsuario } from './perfilUsuario';

function Perfil() {
  const { perfilId } = useParams()
  const { register, handleSubmit, setValue, getValues,formState: { errors } } = useForm();
  
  useEffect(() => {
    if (!perfilId) {
      return;
    }
  }, [perfilId])


  return (
    <>
      <Container className='col-lg-8 col-md-12 col-sm-12  mt-5' fluid>
        <PerfilUsuario errors={errors} perfilId={perfilId} register={register} setValue={setValue} handleSubmit={handleSubmit} getValues={getValues}/>
        <PerfilSenha getValues={getValues} />
      </Container>
    </>
  )
}

export { Perfil }
