import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { FiCheck } from 'react-icons/fi';
import { CampoObrigatorio } from '../../components/campoObrigatorio';

import { Cards } from '../../components/cards'
import { ModalAviso } from '../../components/modalAviso';
import { setValueForm } from '../../libs/setValueForm';
import { useAuth } from '../../services/auth';
import UsuarioService from '../../services/usuario'

function PerfilUsuario({ perfilId, register, setValue, handleSubmit,errors }) {
  const { SignOutForce } = useAuth()
  const [loading, setLoading] = useState(true)
  const [modalAviso, setModalAviso] = useState(false)
  const [showAlertErroServidor, setShowAlertErroServidor] = useState(false)
  const [alertError, setShowAlertError] = useState(false)
  const [messageError, setMessageError] = useState('')

  useEffect(() => {
    async function loadData() {
      try {
        UsuarioService
          .list(perfilId)
          .then((data) => {
            setValueForm(setValue, data)
            setLoading(false)
          })
      } catch (error) {
        setLoading(false)
      }
    }

    loadData()
  }, [setValue, perfilId])

  const onSubmit = (data, e) => {

    async function Editar() {
      UsuarioService
        .patch(perfilId, {
          nome: data.nome,
        })
        .then((dados) => {
          setModalAviso(true)
          setLoading(false)
          setTimeout(() => {
            setModalAviso(false)
          }, 2000);
          window.location.reload()
        })
        .catch((error) => {
          if (error.code) {
            switch (error.code) {
              case 0:
                setShowAlertErroServidor(true)
                setLoading(false)
                setTimeout(() => {
                  setShowAlertErroServidor(false)
                }, 5000)
                break
              case 403:
                SignOutForce()
                window.location.reload()
                break
              case 409:
                setMessageError(error.message)
                setShowAlertError(true)
                setLoading(false)
                setTimeout(() => {
                  setShowAlertError(false)
                  setLoading(false)
                }, 3000)
                break
              default:
                setMessageError(error.message)
                setShowAlertError(true)
                setTimeout(() => {
                  setShowAlertError(false)
                  setLoading(false)
                }, 3000)
                break
            }
          } else {
            setMessageError(error.message)
            setShowAlertError(true)
            setLoading(false)
          }
        })
    }


    Editar()
  }

  return (
    <>
      <ModalAviso show={modalAviso} message='Nome do usuário alterado com sucesso!' color='bg-success' backdrop={false} />
      <ModalAviso show={showAlertErroServidor} message='Verifique sua conexão com a internet.' color='bg-danger' backdrop={false} />
      <ModalAviso show={alertError} message={messageError} color='bg-warning' backdrop={false} />
      <form id='formPerfilUsuario' onSubmit={handleSubmit(onSubmit)} >
        <Cards>
          <div className='row'>
            <div className='col-sm-12 col-md-6 mb-3'>
              <div className='form-group'>
                <label>Nome <CampoObrigatorio /></label>
                <input className='form-control' type='text' {...register('nome',{required: 'Informe seu nome!'})} autoFocus />
              </div>
              <span className='text-danger text-small d-block mb-2 mt-2'>{errors.nome?.message}</span>

            </div>
            <div className='col-sm-12 col-md-6 mb-3'>
              <div className='form-group'>
                <label>E-mail</label>
                <input className='form-control' id='usuario' type='text' readOnly {...register('email')} />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col' ></div>
            <div className='col-auto'>
              <button className='btn btn-success' type='submit' disabled={loading}>
                {
                  loading ? <><Spinner animation='border' size='sm' /> Aguarde...</> : <><FiCheck /> Salvar</>
                }
              </button>
            </div>
          </div>
        </Cards>
      </form>
    </>
  )
}

export { PerfilUsuario }
