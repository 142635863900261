import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { FiPlus } from 'react-icons/fi'

import TableWithSubcomponent from '../../components/tableWithSubcomponent'

function ModalAcompanhamentoGrupoAcao(props) {

  useEffect(() => {
    if (!props.grupoCarregado) {
      return;
    }

    props.setDadosGrupoTabela(props.dbGrupo)
  }, [props, props.grupoCarregado])

  const addGrupo = dados => {
    props.setGrupoNovo({
      id: dados.id,
      nome: dados.nome
    })
  }


  const columns = [
    {
      Header: 'Descrição',
      accessor: 'nome'
    },
    {
      Header: 'Ações',
      id: 'deletar',
      accessor: (str) => 'deletar',
      Cell: (tableProps) => (
        <>
          <button className='btn btn-sm btn-outline-success'
            onClick={() => {
              let dataCopy = props.dadosGrupoTabela
              const grupoSelecionado = props.dadosGrupoTabela[tableProps.row.index]
              addGrupo(grupoSelecionado)
              props.setDadosGrupoTabela(dataCopy)
              props.setModalGrupoAcao(false)
            }}
          >
            <FiPlus />
          </button>
        </>
      )
    }
  ];

  if (!props.show) {
    return <></>;
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      id='modalGrupoAcao'
    >
      <Modal.Header custom closeButton >
        <Modal.Title>Grupo de ação</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col tagGrupo'>
            <TableWithSubcomponent
              columns={columns}
              data={props.dbGrupo}
              size={5} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export { ModalAcompanhamentoGrupoAcao }
