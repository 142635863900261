import React from 'react'

import { useAuth } from '../services/auth'
import Loading from '../static/loading'
import Main from '../pages/main'

function Router() {
  const { isLoading } = useAuth()

  return (isLoading) ? <Loading /> : <Main />
}

export default Router
