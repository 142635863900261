import React, { useEffect, useMemo, useState } from 'react';
import { Button, Container, Navbar } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FiArrowLeft, FiCheck, FiPlus, FiSearch } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';

import { useAuth } from '../../services/auth';
import AcompanhamentoBeneficioService from '../../services/acompanhamentoBeneficio';
import ClienteService from '../../services/cliente';
import FormSanitize from '../../libs/form.sanitize';
import { setValueForm } from '../../libs/setValueForm';
import FormValidate from '../../libs/form.validate';
import { FormatDateToBR } from '../../libs/formatDate';
import { Cards } from '../../components/cards';
import { ModalAviso } from '../../components/modalAviso';
import { TableSimple } from '../../components/tableSimple';
import ModalEncaminharUsuario from '../../components/modalEncaminharUsuario';

import { ModalAcompanhamentoGrupoAcao } from './modalAcompanhamentoGrupoAcao'
import ModalConfirmacao from './modalConfirmacao';

import './acompanhamentoBeneficioEstilo.css';

function AcompanhamentoBeneficioCadastro2() {
  const { register, handleSubmit, setValue, setFocus, reset, getValues } = useForm();
  const { SignOutForce, user } = useAuth()

  const { acompanhamentoId } = useParams()

  const [modalConfirmacao, setModalConfirmacao] = useState(false)
  const [modalAvisoSucesso, setModalAvisoSucesso] = useState(false)
  const [modalAvisoAlerta, setModalAvisoAlerta] = useState(false)
  const [modalAvisoErroServidor, setModalAvisoErroServidor] = useState(false)
  const [modalEncaminharUsuario, setModalEncaminharUsuario] = useState(false)
  const [modalCarregarPagina, setModalCarregarPagina] = useState(true)
  const [modalGrupoAcao, setModalGrupoAcao] = useState(false)
  const [messageError, setMessageError] = useState('')

  const [linkEncaminhamento, setLinkEncaminhamento] = useState('')
  const [dadosTabela, setDadosTabela] = useState([])
  const [dbGrupo, setDbGrupo] = useState([])
  const [grupoNovo, setGrupoNovo] = useState({})
  const [dadosGrupoTabela, setDadosGrupoTabela] = useState([])
  const [grupoCarregado, setGrupoCarregado] = useState(false)
  const [dadosGrupoEdicao, setDadosGrupoEdicao] = useState([])

  const [camposSomenteLeitura, setCamposSomenteLeitura] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (!acompanhamentoId) {
      setModalCarregarPagina(false)
      setValue('recebimentos.data_recebimento', FormSanitize.DateHourNow().dateNow)
      setValue('recebimentos.hora_recebimento', FormSanitize.DateHourNow().hourNow)
      setValue('data_cadastro', FormSanitize.DateHourNow().dateNow)
      setValue('hora_cadastro', FormSanitize.DateHourNow().hourNow)
      setValue('usuario_cadastro.nome', user.nome)
      return;
    }

    function loadData() {
      try {
        AcompanhamentoBeneficioService
          .list(acompanhamentoId)
          .then(async (dados) => {
            const data = dados

            if (data.data_finalizacao) setCamposSomenteLeitura(true)

            setValueForm(setValue, data)
            setValue('pessoa.cpf', FormValidate.onChange.CPF(data.pessoa.cpf))
            setValue('grupo_acao.nome', data.grupo_acao.nome)
            setValue('recebimentos', '');
            setValue('recebimentos.data_recebimento', FormSanitize.DateHourNow().dateNow)
            setValue('recebimentos.hora_recebimento', FormSanitize.DateHourNow().hourNow)
            setFocus('recebimentos.observacao')

            if (data.recebimentos.length > 0) {
              const recebimentos = data.recebimentos.map((value, index) => {
                value.observacao = value.observacao.replace(/\\n/g, "\n");
                return value
              })

              data.recebimentos = recebimentos;
            }

            setDadosTabela(data.recebimentos)
            setDadosGrupoEdicao(data.grupo_acao.id)
            setModalCarregarPagina(false)
          })
      } catch (error) {
        console.error('erro ao carregar dados do acompanhamento: ', error)
        setModalAvisoAlerta(true)
        setModalCarregarPagina(true)
      }
    }

    if (modalCarregarPagina) {
      loadData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo(() => [
    {
      Header: 'Data recebimento',
      accessor: dataRecebimentoProps => {
        return `${FormatDateToBR(dataRecebimentoProps.data_recebimento)} - ${dataRecebimentoProps.hora_recebimento.slice(-8, 5)}`
      }
    },
    {
      Header: 'Observação',
      accessor: 'observacao',
      Cell: ({ value }) => <div style={{ whiteSpace: "pre-wrap" }}>{value}</div>,
    },

  ], [])

  function handleErrors(error) {
    setModalConfirmacao(false)
    setModalCarregarPagina(false)

    if (error.code) {
      switch (error.code) {
        case 0:
          setModalAvisoErroServidor(true)
          setTimeout(() => {
            setModalAvisoErroServidor(false)
          }, 5000)
          break;
        case 401 || 403:
          SignOutForce()
          window.location.reload()
          break;
        case 409:
          setMessageError(error.message)
          setModalAvisoAlerta(true)
          setTimeout(() => {
            setModalAvisoAlerta(false)
          }, 3000)
          break;
        default:
          setMessageError(error.message)
          setModalAvisoAlerta(true)
          setTimeout(() => {
            setModalAvisoAlerta(false)
          }, 5000)
      }
    } else {
      setMessageError(error.message)
      setModalAvisoAlerta(true)
      setTimeout(() => {
        setModalAvisoAlerta(false)
      }, 4000)
    }
  }

  const onSubmit = (data, e) => {
    e.preventDefault()

    if (!data.pessoa.cpf) {
      setMessageError('Nenhum CPF informado!')
      setModalAvisoAlerta(true)
      setTimeout(() => {
        setModalAvisoAlerta(false)
      }, 3000)
      return;
    }

    if (!data.grupo_acao.nome) {
      setMessageError('Nenhum grupo de ação selecionado!')
      setModalAvisoAlerta(true)
      setTimeout(() => {
        setModalAvisoAlerta(false)
      }, 3000)
      return;
    }

    data.pessoa_cpf = FormSanitize.Sanitize(data.pessoa.cpf)
    data.recebimentos = [data.recebimentos]

    if (!acompanhamentoId) {
      async function Registrar() {
        try {
          data.grupo_acao = grupoNovo.id
          data.data_finalizacao = null;
          data.hora_finalizacao = null;

          AcompanhamentoBeneficioService
            .register(data)
            .then(() => {
              setModalAvisoSucesso(true)
              setModalConfirmacao(false)

              setTimeout(() => {
                setModalAvisoSucesso(false)
                setModalCarregarPagina(false)
              }, 2000)

              reset();

              setValue('recebimentos.data_recebimento', FormSanitize.DateHourNow().dateNow)
              setValue('recebimentos.hora_recebimento', FormSanitize.DateHourNow().hourNow)
              setValue('data_cadastro', FormSanitize.DateHourNow().dateNow)
              setValue('hora_cadastro', FormSanitize.DateHourNow().hourNow)
              setValue('usuario_cadastro.nome', user.nome)
              setGrupoNovo({ nome: '' })
            })
            .catch((error) => {
              handleErrors(error)
            })
        } catch (error) {
          setModalCarregarPagina(false)
          setModalAvisoAlerta(true)
          setTimeout(() => {
            setModalAvisoAlerta(false)
          }, 5000)
        }
      }

      setModalCarregarPagina(true)
      Registrar()
    } else {
      async function Editar() {
        data.grupo_acao = dadosGrupoEdicao
        data.data_finalizacao = null;
        data.hora_finalizacao = null;
        


        AcompanhamentoBeneficioService
          .update(acompanhamentoId, data)
          .then(() => {
            setModalAvisoSucesso(true)
            setTimeout(() => {
              setModalConfirmacao(false)
              setModalAvisoSucesso(false)
              history.push('/acompanhamento/beneficio')
            }, 2000)
          })
          .catch((error) => {
            handleErrors(error)
          })
      }

      setModalCarregarPagina(true)
      Editar()
    }
    setFocus('recebimentos.observacao')
  }

  const handleClickFiltroCpf = () => {
    let strFiltroCpf = document.getElementById('txtPessoaCpf').value
    const clienteId = FormSanitize.Sanitize(strFiltroCpf)

    setValue('pessoa.cpf', strFiltroCpf)

    ClienteService
      .listCPF(clienteId)
      .then(dados => {
        const data = dados.data;

        if (data.length === 0) {
          setMessageError('CPF não encontrado ou não está ativado!')
          setModalAvisoAlerta(true)
          setTimeout(() => {
            setModalAvisoAlerta(false)
          }, 3000)

          return
        }

        if (data[0].cpf) {
          setModalCarregarPagina(false)
          setValue('pessoa.nome', data[0].nome)
          setValue('recebimentos.data_recebimento', FormSanitize.DateHourNow().dateNow)
          setValue('recebimentos.hora_recebimento', FormSanitize.DateHourNow().hourNow)

          if (data[0].grupo_acao.length < 1) {
            setMessageError('Nenhum grupo de ação encontrado para este cliente.')
            setModalEncaminharUsuario(true)
            setLinkEncaminhamento('/cliente/cadastro/' + data[0].id)
            setTimeout(() => {
              setModalEncaminharUsuario(false)
            }, 100000)
          }

          setDbGrupo(data[0].grupo_acao)
          setGrupoNovo(data[0].grupo_acao)
          document.getElementById('btnFiltroGrupo').focus()
          setGrupoCarregado(true)
        }
      })
      .catch(error => { handleErrors(error) })
  }

  return (
    <>
      <Container className='col-lg-8 col-md-12 col-sm-12  mt-5' fluid>
        <ModalEncaminharUsuario show={modalEncaminharUsuario} message={messageError} color='bg-warning' backdrop={true} link={linkEncaminhamento} encaminhar='Vincular grupo de ação' />
        <ModalAviso show={modalAvisoSucesso} message='O acompanhamento de benefício foi salvo!' color='bg-success' backdrop={false} />
        <ModalAviso show={modalAvisoAlerta} message={messageError} color='bg-warning' backdrop={false} />
        <ModalAviso show={modalAvisoErroServidor} message='Verifique sua conexão com a internet.' color='bg-danger' backdrop={false} />
        <ModalAviso show={modalCarregarPagina} message='Carregando registro...' color='bg-light' backdrop={true} />
        <ModalAcompanhamentoGrupoAcao
          show={modalGrupoAcao}
          onHide={() => setModalGrupoAcao(false)}
          setGrupoNovo={(dados) => {
            setValue('grupo_acao.nome', dados.nome)
            setGrupoNovo(dados)
          }}
          grupoNovo={grupoNovo}
          dbGrupo={dbGrupo}
          dadosGrupoTabela={dadosGrupoTabela}
          setDadosGrupoTabela={setDadosGrupoTabela}
          setValue={setValue}
          register={register}
          grupoCarregado={grupoCarregado}
          setModalGrupoAcao={setModalGrupoAcao}
        />

        <div className='row'>
          <div className='col mb-3'>
            <h3>Acompanhamento de benefícios</h3>
          </div>
          <div className='col-auto'>
            <Button variant='outline-secondary' onClick={() => window.history.back()}><FiArrowLeft /> Voltar</Button>
          </div>
        </div>

        <br />

        <form style={{ paddingBottom: 55 }}>
          <ModalConfirmacao show={modalConfirmacao} onHide={() => setModalConfirmacao(false)} handleSubmit={handleSubmit(onSubmit)} loading={modalCarregarPagina} />
          <Cards>
            <div className='row'>
              <div className='col-sm-12 col-md-4 mb-3'>
                <label htmlFor='txtPessoaCpf'>CPF</label>
                <div className='input-group'>
                  <input
                    className='form-control'
                    id="txtPessoaCpf"
                    name='cpf'
                    type='text'
                    readOnly={acompanhamentoId ? 'readonly' : ''}
                    onKeyPress={(e) => {
                      if (!acompanhamentoId) {
                        if (e.charCode === 13) {
                          e.preventDefault()
                          e.stopPropagation()
                          handleClickFiltroCpf(e.target.value)
                        }
                      }
                    }}
                    {...register('pessoa.cpf', {
                      ...FormValidate.MinLength(11),
                      ...FormValidate.MaxLength(14),
                      ...FormValidate.CPF,
                    })}
                    onChange={(e) => setValue(e.target.name, FormValidate.onChange.CPF(e.target.value))}
                    autoFocus={acompanhamentoId ? '' : 'autoFocus'}
                  />
                  {
                    acompanhamentoId
                      ? (<></>)
                      : (
                        <button
                          className='btn btn-outline-secondary'
                          id="btnFiltroCliente"
                          type='button'
                          onClick={() => { handleClickFiltroCpf() }}
                          title='Pesquisar CPF'
                        >
                          <FiSearch />
                        </button>
                      )
                  }

                </div>
              </div>
              <div className='col-sm-12 col-md-8 mb-3'>
                <label htmlFor='pessoa.nome'>Cliente</label>
                <input className='form-control' id='pessoa.nome' type='text' readOnly {...register('pessoa.nome')} />
              </div>
            </div>

            <section>
              <div className='row'>
                <div className='col-sm-12 col-md-6 mb-3'>
                  <label htmlFor='grupo_acao'>Grupo de ação</label>
                  <div className='input-group'>
                    <input className='form-control' id='grupo_acao' type='text' {...register('grupo_acao.nome')} readOnly />
                    {
                      acompanhamentoId
                        ? (<></>)
                        : (
                          <button
                            className='btn btn-outline-success'
                            id="btnFiltroGrupo"
                            type='button'
                            onClick={(e) => { setModalGrupoAcao(true) }}
                            title='Adicionar grupo de ação'
                          >
                            <FiPlus />
                          </button>
                        )
                    }
                  </div>
                </div>

                <div className='col-sm-12 col-md-3 mb-3'>
                  <label htmlFor='data_recebimento'>Data recebimento</label>
                  <input className='form-control' id='data_recebimento' type='date' {...register('recebimentos.data_recebimento')} readOnly={camposSomenteLeitura ? 'readOnly' : ''} />
                </div>

                <div className='col-sm-12 col-md-3 mb-3'>
                  <label htmlFor='hora_recebimento'>Hora recebimento</label>
                  <input className='form-control' id='hora_recebimento' type='time' {...register('recebimentos.hora_recebimento')} readOnly={camposSomenteLeitura ? 'readOnly' : ''} />
                </div>
              </div>
            </section>

            <div className='row'>
              <div className='col mb-3'>
                <label htmlFor='observacao'>Observação</label>
                <textarea className='form-control' id='observacao' rows='5' {...register('recebimentos.observacao')} readOnly={camposSomenteLeitura ? 'readOnly' : ''} />
              </div>
            </div>
          </Cards>

          <Cards>
            <div className='table-responsive'>
              <TableSimple columns={columns} data={dadosTabela} />
            </div>
          </Cards>
          <Cards>
            <div className='row'>
              <div className='row'>
                <div className='col'>
                  <h5>Responsável pelo cadastro</h5>
                </div>
              </div>
              <div className='col-sm-12 col-md-6 mb-3'>
                <div className='form-group'>
                  <label htmlFor='usuario'>Usuário</label>
                  <input className='form-control' id='usuario' type='text' readOnly {...register('usuario_cadastro.nome')} />
                </div>
              </div>
              <div className='col-sm-12 col-md-3 mb-3'>
                <div className='form-group'>
                  <label htmlFor='data_cadastro'>Data cadastro</label>
                  <input className='form-control' id='data_cadastro' type='date' readOnly {...register('data_cadastro')} />
                </div>
              </div>
              <div className='col-sm-12 col-md-3 mb-3'>
                <div className='form-group'>
                  <label htmlFor='data_cadastro'>Hora cadastro</label>
                  <input className='form-control' id='data_cadastro' type='time' readOnly {...register('hora_cadastro')} />
                </div>
              </div>
            </div>
          </Cards>

          {
            (camposSomenteLeitura)
              ?
              <>
                <Cards>
                  <div className='row'>
                    <div className='row'>
                      <div className='col'>
                        <h5>Responsável pela finalização</h5>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-6 mb-3'>
                      <div className='form-group'>
                        <label htmlFor='usuario'>Usuário</label>
                        <input className='form-control' id='usuario' type='text' readOnly {...register('usuario_finalizacao.nome')} />
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-3 mb-3'>
                      <div className='form-group'>
                        <label htmlFor='data_finalizacao'>Data finalização</label>
                        <input className='form-control' id='data_finalizacao' type='date' {...register('data_finalizacao')} readOnly={camposSomenteLeitura ? 'readOnly' : ''} />
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-3 mb-3'>
                      <div className='form-group'>
                        <label htmlFor='hora_finalizacao'>Hora finalização</label>
                        <input className='form-control' id='hora_finalizacao' type='time' {...register('hora_finalizacao')} readOnly={camposSomenteLeitura ? 'readOnly' : ''} />
                      </div>
                    </div>
                  </div>
                </Cards>
              </>
              :
              <></>
          }
        </form>


        <Navbar className='d-flex justify-content-end' fixed="bottom" style={{ backgroundColor: '#e5e5e5' }} >
          <div style={{ backgroundColor: '#e5e5e5', width: '100%', display: 'flex', justifyContent: 'flex-end', marginRight: '17.3%' }}>
            <button
              className='btn btn-success mb-3'
              type='button'
              onClick={() => {
                if (!getValues('pessoa.cpf')) {
                  setMessageError('Nenhum CPF informado!')
                  setModalAvisoAlerta(true)
                  setTimeout(() => {
                    setFocus('pessoa.cpf')
                    setModalAvisoAlerta(false)
                  }, 2000)
                  return;
                }

                if (!getValues('grupo_acao.nome')) {
                  setMessageError('Nenhum grupo de ação selecionado!')
                  setModalAvisoAlerta(true)
                  setTimeout(() => {
                    setModalAvisoAlerta(false)
                  }, 2000)
                  return;
                }

                setModalConfirmacao(true)
              }}
              disabled={camposSomenteLeitura ? 'disabled' : ''}><FiCheck /> Salvar</button>
          </div>
        </Navbar>


      </Container>
    </>
  )
}

export { AcompanhamentoBeneficioCadastro2 }
