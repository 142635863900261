import React from 'react'

import { Cards } from '../../components/cards';

function ClienteCadastroHistoricoFamiliar({ register }) {
  return (
    <Cards>
      <div className='row'>
        <div className='col'>
          <h5>Histórico familiar</h5>
        </div>
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <label>Descrição</label>
          <textarea className='form-control' rows='5' {...register('historico_familiar')} />
        </div>
      </div>
    </Cards>
  )
}

export { ClienteCadastroHistoricoFamiliar }
