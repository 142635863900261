import React from 'react'

import { Cards } from '../../components/cards';

function ClienteCadastroEscolaridade({ register }) {

  return (
    <Cards>
      <div className='row'>
        <div className='col'>
          <h5>Escolaridade</h5>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 col-sm-12 mb-3'>
          <label htmlFor='escolaridade_ensino_fundamental'>Ensino fundamental</label>
          <select
            className='form-select'
            {...register('escolaridade_ensino_fundamental')}
          >
            <option value='NAO_POSSUI'>Não possui</option>
            <option value='INCOMPLETO'>Incompleto</option>
            <option value='COMPLETO'>Completo</option>
          </select>
        </div>
        <div className='col-md-6 col-sm-12 mb-3'>
          <label htmlFor='escolaridade_ensino_medio'>Ensino médio</label>
          <select
            className='form-select'
            {...register('escolaridade_ensino_medio')}
          >
            <option value='NAO_POSSUI'>Não possui</option>
            <option value='INCOMPLETO'>Incompleto</option>
            <option value='COMPLETO'>Completo</option>
          </select>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-4 col-sm-12 mb-3'>
          <label htmlFor='escolaridade_ensino_superior'>Ensino técnico</label>
          <select
            className='form-select'
            {...register('escolaridade_curso_tecnico')}
          >
            <option value='NAO_POSSUI'>Não possui</option>
            <option value='INCOMPLETO'>Incompleto</option>
            <option value='COMPLETO'>Completo</option>
          </select>
        </div>
        <div className='col-md-8 col-sm-12 mb-3'>
          <label htmlFor='escolaridade_ensino_tecnico_descricao'> Curso ensino técnico </label>
          <input className='form-control' type='text' {...register('escolaridade_curso_tecnico_descricao')} />
        </div>
      </div>

      <div className='row'>
        <div className='col-md-4 col-sm-12 mb-3'>
          <label htmlFor='escolaridade_ensino_superior'>Ensino superior</label>
          <select
            className='form-select'
            {...register('escolaridade_ensino_superior')}
          >
            <option value='NAO_POSSUI'>Não possui</option>
            <option value='INCOMPLETO'>Incompleto</option>
            <option value='COMPLETO'>Completo</option>
          </select>
        </div>
        <div className='col-md-8 col-sm-12 mb-3'>
          <label htmlFor='escolaridade_ensino_superior_descricao'> Curso ensino superior </label>
          <input className='form-control' type='text' {...register('escolaridade_ensino_superior_descricao')} />
        </div>
      </div>
    </Cards>
  )
}

export { ClienteCadastroEscolaridade }
