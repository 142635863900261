const FormValidate = {}

FormValidate.Require = {
  required: {
    value: true,
    message: 'Campo obrigatório'
  }
}
FormValidate.Alpha = {
  pattern: {
    value: /^[A-Za-z]+$/i,
    message: "Digite somente letras!"
  }
}
FormValidate.Numeric = {
  pattern: {
    value: /\d+/,
    message: "Digite somente números!"
  }
}
FormValidate.AlphaNumeric = {
  pattern: {
    value: /^[A-Za-z0-9]+$/i,
    message: "Digite um texto alfa numérico!",
  }
}
FormValidate.Email = {
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "Digite um e-mail válido!",
  },
}

FormValidate.MaxLength = (num = 100) => {
  return {
    maxLength: {
      value: num,
      message: 'Máximo ' + num + ' caracteres'
    }
  }
}

FormValidate.MinLength = (num = 2) => {
  return {
    minLength: {
      value: num,
      message: 'Mínimo ' + num + ' caracteres'
    }
  }
}

FormValidate.CPF = {
  validate: {
    cpfValidation: (cpf) => {
      cpf = cpf?.replace(/[^\d]+/g, '')

      if (cpf === '') return false

      // Elimina CPFs invalidos conhecidos	
      if (cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999")
        return false

      // Valida 1o digito	
      let add = 0
      let i

      for (i = 0; i < 9; i++)
        add += parseInt(cpf.charAt(i)) * (10 - i)

      let rev = 11 - (add % 11)

      if (rev === 10 || rev === 11) rev = 0

      if (rev !== parseInt(cpf.charAt(9))) return false

      add = 0;
      for (i = 0; i < 10; i++)
        add += parseInt(cpf.charAt(i)) * (11 - i)

      rev = 11 - (add % 11)

      if (rev === 10 || rev === 11) rev = 0
      if (rev !== parseInt(cpf.charAt(10))) return false

      return true
    }
  } || 'CPF inválido!'
}

FormValidate.CNPJ = {
  validate: cnpj => {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') {
      return false;
    }

    if (cnpj.length !== 14) {
      return false;
    }

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999"
    ) {
      return false;
    }

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    let i
    let resultado

    for (i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

    if (resultado !== parseInt(digitos.charAt(0)))
      return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(1)))
      return false;

    return true;
  }
}

FormValidate.onChange = {}

FormValidate.onChange.Moeda = value => {
  let valor = value;

  valor = valor + '';
  valor = parseInt(valor.replace(/[\D]+/g, ''));
  valor = valor + '';
  valor = valor.replace(/([0-9]{2})$/g, ",$1");

  if (valor.length > 6) {
    valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  }

  if (valor === 'NaN') {
    valor = '';
  }

  return valor
}

FormValidate.onChange.ParseMoeda = value => {

  if (value === "") {
    value = 0;
  } else {
    value = value.replace(".", "");
    value = value.replace(",", ".");
    value = parseFloat(value);
  }
  return value;


}

FormValidate.onChange.SemEspaco = value => {
  if (!value) return value

  return value
    .replace(/\s/g, '')
}

FormValidate.onChange.CPF = value => {
  if (!value) return value

  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

FormValidate.onChange.CNPJ = value => {
  if (!value) return value

  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

FormValidate.onChange.Telefone = value => {
  if (!value) return value

  let v = value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/g, '($1) $2')

  if (v.length < 14) v = v.replace(/(\d{4})(\d{1,2})/, '$1-$2')
  else v = v.replace(/(\d{5})(\d{1,2})/, '$1-$2')

  return v.replace(/(-\d{4})\d+?$/, '$1')
}

FormValidate.onChange.Cep = value => {
  if (!value) return value

  return value
    .replace(/\D/g, '')
    .replace(/(\d{8})\d+?$/, '$1')
}

export default FormValidate