import React from 'react'

function Cards(props) {
  const className = props.className;
  
  return (
    <div className={'card mb-3 ' + (!className ? '' : className)} style={{ backgroundColor: '#f5f5f5', ...props.style }}>
      <div className='card-body'>{ props.children }</div>
    </div>
  )
}

export { Cards }
