
import React from 'react'
import { Modal } from 'react-bootstrap'

function ModalAviso({ show, color, message, backdrop }) {
  return (
    <Modal
      show={show}
      onHide={() => {}}
      size='sm'
      aria-labelledby='modal-aviso'
      contentClassName={color}
      backdrop={backdrop}
      centered
    >
      <Modal.Body>
        <p className={(color === 'bg-light' || color === 'bg-warning') ? '' : 'text-white'}>{message}</p>
      </Modal.Body>
    </Modal>
  )
}

export { ModalAviso }
