import { Modal } from 'react-bootstrap'
import React from 'react'
import { Link } from 'react-router-dom'

function ModalEncaminharUsuario({ show, color, message, backdrop, link, encaminhar }) {
  return (
    <Modal
    show={show}
    onHide={() => {}}
    size='sm'
    aria-labelledby='modal-aviso'
    contentClassName={color}
    backdrop={backdrop}
    centered
  >
    <Modal.Body>
      <p className={(color === 'bg-light' || color === 'bg-warning') ? '' : 'text-white'}>{message}</p>{' '}
      <Link className='btn btn-sm btn-dark mt-3' to={link}> {encaminhar} </Link>
    </Modal.Body>
  </Modal>
  )
}

export default ModalEncaminharUsuario
