import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Cliente } from './pages/Cliente'
import { ClienteCadastro } from './pages/Cliente/clienteCadastro'
import { GrupoAcaoLista } from './pages/GrupoAcao'
import { GrupoAcaoCadastro } from './pages/GrupoAcao/grupoAcaoCadastro'
import { Login } from './pages/Login'
import { UsuarioLista } from './pages/Usuario'
import { UsuarioCadastro } from './pages/Usuario/usuarioCadastro'
import RecuperarSenha from './pages/Login/recuperarSenha'
import { Perfil } from './pages/Perfil'

import PrivateLogin from './routes/privateLogin'
import PrivateRoute from './routes/privateRoute'
import { NotFound } from './static/notfound'
import { AcompanhamentoBeneficioCadastro2 } from './pages/AcompanhamentoBeneficio2/acompanhamentoBeneficioCadastro2'
import { AcompanhamentoBeneficioLista2 } from './pages/AcompanhamentoBeneficio2'
import { Aniversariantes } from './pages/Aniversariantes/Aniversariantes'

function Routes() {
  return (
      <Switch>
        <PrivateLogin path='/' exact component={Login} />
        <Route path='/recuperar-senha' component={RecuperarSenha} />

        {/* GRUPO DE AÇÃO */}
        <PrivateRoute path='/grupo-acao' exact component={GrupoAcaoLista} />
        <PrivateRoute path='/grupo-acao/cadastro/:grupoId' component={GrupoAcaoCadastro} />
        <PrivateRoute path='/grupo-acao/cadastro/' component={GrupoAcaoCadastro} />

        {/* ACOMPANHAMENTO DE BENEFICIO */}
        <PrivateRoute path='/acompanhamento/beneficio' exact component={AcompanhamentoBeneficioLista2} />
        <PrivateRoute path='/acompanhamento/beneficio/cadastro/:acompanhamentoId' component={AcompanhamentoBeneficioCadastro2} />
        <PrivateRoute path='/acompanhamento/beneficio/cadastro/' component={AcompanhamentoBeneficioCadastro2} />

        {/* ACOMPANHAMENTO DE BENEFICIO 2 */}
        {/* <PrivateRoute path='/acompanhamento2/beneficio2' exact component={AcompanhamentoBeneficioLista2} /> */}
        {/* <PrivateRoute path='/acompanhamento2/beneficio2/cadastro2/:acompanhamentoId2' component={AcompanhamentoBeneficioCadastro2} /> */}
        {/* <PrivateRoute path='/acompanhamento2/beneficio2/cadastro2/' component={AcompanhamentoBeneficioCadastro2} /> */}

        {/* CLIENTE */}
        <PrivateRoute path='/cliente' exact component={Cliente} />
        <PrivateRoute path='/cliente/cadastro/:clienteId' component={ClienteCadastro} />
        <PrivateRoute path='/cliente/cadastro' component={ClienteCadastro} />

        {/* CLIENTES COM FILTRO PRO MES */}
        <PrivateRoute path='/aniversariantes' exact component={Aniversariantes} />

        {/* USUARIO */}
        <PrivateRoute path='/usuario' exact component={UsuarioLista} />
        <PrivateRoute path='/usuario/cadastro/:usuarioId' component={UsuarioCadastro} />
        <PrivateRoute path='/usuario/cadastro/' component={UsuarioCadastro} />

        {/* PERFIL */}
        <PrivateRoute path='/perfil/:perfilId' exact component={Perfil} />
        <Route component={NotFound} />
      </Switch>

  )
}

export default Routes
