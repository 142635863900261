import React, { useState, useEffect } from 'react'
import { FiPlus } from 'react-icons/fi'

import { Cards } from '../../components/cards'
import { TagsStatic } from '../../components/tagsStatic'
import ModalGrupoAcao from './modalGrupoAcao'

function ClienteCadastroGrupoAcao(props) {
  const [modalGrupoAcao, setModalGrupoAcao] = useState(false)
  const [dataTabela, setDataTabela] = useState([])
  const [grupo, setGrupo] = useState([])

  useEffect(() => {
    if (!props.dadoCarregado) {
      return;
    }
    setGrupo(props.dadoGrupoAcao)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dadoCarregado])


  const removerTagGrupo = index => {
    const tagGrupo = [...grupo]

    tagGrupo.splice(index, 1)

    const propsDBCopy = dataTabela

    propsDBCopy.unshift(grupo[index])

    props.setDB(propsDBCopy)
    setGrupo(tagGrupo)
  }

  const renderGrupo = () => {
    props.setValue('grupo_acao', [...grupo])
    return (
      grupo.map((value, index) => (
        <>
          <TagsStatic
            chave={index}
            nome={value.nome}
            remover={removerTagGrupo}
            key={index}
          />
        </>
      )
      )
    )

  }

  return (
    <Cards>
      <ModalGrupoAcao
        show={modalGrupoAcao}
        onHide={() => setModalGrupoAcao(false)}
        renderGrupo={renderGrupo}
        setGrupoNovo={setGrupo}
        grupoNovo={grupo}
        db={props.db}
        dataTabela={dataTabela}
        setDataTabela={setDataTabela}
        register={props.register}
        setValue={props.setValue}
      />
      <section>
        <div className='row'>
          <div className='col-md-4 col-sm-12 mb-3'>
            <h5>Grupo de ação</h5>
          </div>
          <div className='col-md-6 col-sm-12 mb-3'>
            <button
              className='btn btn-outline-success'
              type='button'
              onClick={() => setModalGrupoAcao(true)}
              style={{ width: '200px' }}
            >
              <FiPlus /> Adicionar grupo
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            {renderGrupo()}
          </div>
        </div>
      </section>
    </Cards>
  )
}

export { ClienteCadastroGrupoAcao }
