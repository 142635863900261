import React from 'react'
import { Spinner, Container, Row, Col } from 'react-bootstrap'

function Loading() {
    return (
        <Container>
            <Row style={{ minHeight: 'calc(100vh - 40px)' }} className='align-items-center'>
                <Col className='text-center'>
                    <strong><h3>Carregando aguarde um momento...</h3></strong>
                    <Spinner animation="border" size="lg" />
                </Col>
            </Row>
        </Container>
    )
}

export default React.memo(Loading)
