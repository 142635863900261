import API from '../config/api'
import security from './security'

const auth = {}

auth.login = body => {
    return security.validation(API.axios.post(API.api.auth.post.login, body))
}

auth.logout = body => {
    return security.validation(API.axios.post(API.api.auth.post.logout))
}

auth.register = body => {
    return security.validation(API.axios.post(API.api.users.post.register, body))
}

auth.registeractive = body => {
    return security.validation(API.axios.post(API.api.users.post.registerActive, body))
}

auth.registersendmail = body => {
    return security.validation(API.axios.post(API.api.users.post.registerSendConfirmation, body))
}

auth.forgot = body => {
    return security.validation(API.axios.post(API.api.auth.post.forgot, body))
}

auth.forgotreset = body => {
    return security.validation(API.axios.post(API.api.auth.post.forgotReset, body))
}

auth.me = () => {
  return security.validation(API.axios.get(API.api.auth.get.me))
}

export default auth