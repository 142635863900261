import FormSanitize from "../../../libs/form.sanitize"


const RegisterSanitize = {}

RegisterSanitize.SanitizeData = (data) => {
  if (data.cpf) {
    data.cpf = FormSanitize.Sanitize(data.cpf)
  }

  if (data.telefone) {
    data.telefone = FormSanitize.Sanitize(data.telefone)
  }

  if (data.telefone2) {
    data.telefone2 = FormSanitize.Sanitize(data.telefone2)
  }

  if (data.telefone3) {
    data.telefone3 = FormSanitize.Sanitize(data.telefone3)
  }

  //moradia
  if (!data.moradia_numero_comodos) {
    data.moradia_numero_comodos = 1
  }

  data.moradia_gasto_agua = SanitizeMoeda(data.moradia_gasto_agua)
  data.moradia_gasto_energia = SanitizeMoeda(data.moradia_gasto_energia)
  data.moradia_gasto_gas = SanitizeMoeda(data.moradia_gasto_gas)
  data.moradia_gasto_alimentacao = SanitizeMoeda(data.moradia_gasto_alimentacao)
  data.moradia_gasto_remedio = SanitizeMoeda(data.moradia_gasto_remedio)
  data.moradia_gasto_internet = SanitizeMoeda(data.moradia_gasto_internet)
  data.moradia_gasto_aluguel = SanitizeMoeda(data.moradia_gasto_aluguel)
  data.moradia_gasto_educacao = SanitizeMoeda(data.moradia_gasto_educacao)
  data.moradia_gasto_outros = SanitizeMoeda(data.moradia_gasto_outros)

  //emprego
  data.emprego_renda = SanitizeMoeda(data.emprego_renda)

  //beneficíos
  data.beneficio_doacao = SanitizeMoeda(data.beneficio_doacao)
  data.beneficio_bolsa_familia = SanitizeMoeda(data.beneficio_bolsa_familia)
  data.beneficio_outros = SanitizeMoeda(data.beneficio_outros)

  //veiculo
  data.veiculo_parcela = SanitizeMoeda(data.veiculo_parcela)

  // Composicao Familiar
  if (data.composicao_familiar.length > 0) {
    const composicao_familiar = data.composicao_familiar.map((value, index) => {
      value.emprego_renda = SanitizeMoeda(value.emprego_renda)
      return value
    })

    data.composicao_familiar = composicao_familiar;
  }

  return data
}

export default RegisterSanitize


function SanitizeMoeda(moeda) {
  if (typeof moeda === 'string') {
    if (!moeda) return 0.00

    let Valor = moeda.replace('.', '').replace(',', '.')
    return parseFloat(Valor)
  } else {
    return moeda
  }
}